import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

// Estadísticas de Registro
const RegistersContainer = lazy(() => import('./registers/registersContainer'))

// Estadísticas de Solicitudes por Tipo de Licencia
const SummaryRequestsContainer = lazy(() => import('./summaryRequests/summaryRequestsContainer'))

// Estadísticas de Solicitudes por División
const SummaryRequestsDivisionContainer = lazy(() => import('./summaryRequestsDivision/summaryRequestsDivisionContainer'))

// Estadísticas de Solicitudes por Servicio
const SummaryServicesContainer = lazy(() => import('./summaryServices/summaryServicesContainer'))

// Resumen de Asignación de Usuarios a Solicitudes
const SummaryAssignmentRequestsContainer = lazy(() => import('./summaryAssignmentRequests/summaryAssignmentRequestsContainer'))

// Resumen de Mensajes Enviados por Usuario
const SummaryMessagesSentUserContainer = lazy(() => import('./summaryMessagesSentUser/summaryMessagesSentUserContainer'))

// Resumen de Documentos Validados por Usuario
const SummaryDocumentsValidatedUserContainer = lazy(() => import('./summaryDocumentsValidatedUser/summaryDocumentsValidatedUserContainer'))

// Resumen de Solicitudes Vencidas / Próximas Renovaciones
const OverdueRequestsContainer = lazy(() => import('./overdueRequests/overdueRequestsContainer'))

// Métrica de Tiempo de Operación (Días)
const SummaryMetricTimeHistoryContainer = lazy(() => import('./summaryMetricTimeHistory/summaryMetricTimeHistoryContainer'))

// Estadísticas de Ventas
const SalesContainer = lazy(() => import('./sales/salesContainer'))

// Reporte de Clientes
const ClientsContainer = lazy(() => import('./clients/clientsContainer'))

// Notificación de Renovaciones
const RevonationContainer = lazy(() => import('../requests/renovations/renovationContainer'))

// Reporte de Licencias NTSP
const LicensesNtspContainer = lazy(() => import('./licensesNtsp/licensesNtspContainer'))

// Estadísticas de Servicios NTSP
const SummaryNtspContainer = lazy(() => import('./summaryNtsp/summaryNtspContainer'))

// Reporte de Infracciones (Boletos)
const InfractionsContainer = lazy(() => import('./infractions/infractionsContainer'))

// Reporte de Boletos (NTSP)
const TicketsContainer = lazy(() => import('./tickets/ticketsContainer'))

// Reporte de Solicitudes SARAFS
const LicensesSarafsContainer = lazy(() => import('./licensesSarafs/licensesSarafsContainer'))

// Directorio de Facilidades - SARAFS
const ClientDirectorySarafsContainer = lazy(() => import('./clientDirectorySarafs/clientDirectorySarafsContainer'))

// Reporte de Certificados de Salud (Sellos)
const LicensesSealsContainer = lazy(() => import('./licensesSeals/licensesSealsContainer'))

// Reporte de Certificados de Salud (Total de Sellos por Médico)
const LicensesSealsDoctorContainer = lazy(() => import('./licensesSealsDoctor/licensesSealsDoctorContainer'))

// Reporte de Médicos
const DoctorsContainer = lazy(() => import('./doctors/doctorsContainer'))

// Reporte de Solicitudes Oficina de Investigaciones
const LicensesSuscontContainer = lazy(() => import('./licensesSuscont/licensesSuscontContainer'))

// Reporte de Contratistas (DACO)
const ContractorsContainer = lazy(() => import("./contractors/contractorsContainer"))

// Reporte de Hospedajes (DACO)
const LodgingsContainer = lazy(() => import("./lodgings/lodgingsContainer"))

// Reporte de Defunciones (Registro Demográfico)
const DeathsContainer = lazy(() => import("./deaths/deathssContainer"))

//Estadísticas de Facturas Creadas por Usuarios de Agencia: Supervisor, Inspector
const SummaryInvoiceCreateUserContainer = lazy(() => import("./invoiceCreateUser/summaryInvoiceCreateUser"))

// Reporte de Historial de Devoluciones de Solicitud
const HistoryRequestsReturnsContainer = lazy(() => import("./historyRequestsReturns/historyRequestsReturns"))

// Reporte de Historial Generación de Licencias
const HistoryGenerationLicensesContainer = lazy(() => import("./historyGenerationLicenses/historyGenerationLicenses"))

const reportsRoutes = [

    // Reporte de Historial Generación de Licencias
    {
        path: "/reports/license_generation_histories",
        component: HistoryGenerationLicensesContainer,
        auth: authRoles.admin
    },

    // Reporte de Historial de Devoluciones de Solicitud
    {
        path: "/reports/view_return_history",
        component: HistoryRequestsReturnsContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Registro
    {
        path: "/reports/registers",
        component: RegistersContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Solicitudes por Tipo de Licencia
    {
        path: "/reports/summary_requests",
        component: SummaryRequestsContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Solicitudes por Tipo de Licencia
    {
        path: "/reports/summary_requests_division",
        component: SummaryRequestsDivisionContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Solicitudes por Servicio
    {
        path: "/reports/summary_services",
        component: SummaryServicesContainer,
        auth: authRoles.admin
    },

    // Resumen de Asignación de Usuarios a Solicitudes
    {
        path: "/reports/summary_assignment_requests",
        component: SummaryAssignmentRequestsContainer,
        auth: authRoles.admin
    },

    // Resumen de Mensajes Enviados por Usuario
    {
        path: "/reports/summary_messages_sent_user",
        component: SummaryMessagesSentUserContainer,
        auth: authRoles.admin
    },

    // Resumen de Documentos Validados por Usuario
    {
        path: "/reports/summary_documents_validated_user",
        component: SummaryDocumentsValidatedUserContainer,
        auth: authRoles.admin
    },

    // Resumen de Solicitudes Vencidas / Próximas Renovaciones
    {
        path: "/reports/overdue_requests",
        component: OverdueRequestsContainer,
        auth: authRoles.admin
    },

    // Métrica de Tiempo de Operación (Días)
    {
        path: "/reports/summary_metric_time_history",
        component: SummaryMetricTimeHistoryContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Ventas
    {
        path: "/reports/sales",
        component: SalesContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Ventas
    {
        path: "/reports/clients",
        component: ClientsContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Ventas
    {
        path: "/reports/renovations",
        component: RevonationContainer,
        auth: authRoles.admin
    },

    // Reporte de Licencias NTSP
    {
        path: "/reports/licenses_ntsp",
        component: LicensesNtspContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Servicios NTSP
    {
        path: "/reports/summary_ntsp",
        component: SummaryNtspContainer,
        auth: authRoles.admin
    },

    // Estadísticas de Servicios NTSP
    {
        path: "/reports/infractions",
        component: InfractionsContainer,
        auth: authRoles.admin
    },

    // Reporte de Boletos (NTSP)
    {
        path: "/reports/tickets",
        component: TicketsContainer,
        auth: authRoles.admin
    },

    // Reporte de Solicitudes SARAFS
    {
        path: "/reports/licenses_sarafs",
        component: LicensesSarafsContainer,
        auth: authRoles.admin
    },

    // Directorio de Facilidades - SARAFS
    {
        path: "/reports/client_directory_sarafs",
        component: ClientDirectorySarafsContainer,
        auth: authRoles.admin
    },

    // Reporte de Certificados de Salud (Sellos)
    {
        path: "/reports/licenses_seals",
        component: LicensesSealsContainer,
        auth: authRoles.admin
    },

    // Reporte de Certificados de Salud (Total de Sellos por Médico)
    {
        path: "/reports/licenses_seals_doctor",
        component: LicensesSealsDoctorContainer,
        auth: authRoles.admin
    },

    // Reporte de Certificados de Salud (Total de Sellos por Médico)
    {
        path: "/reports/doctors",
        component: DoctorsContainer,
        auth: authRoles.admin
    },

    // Reporte de Solicitudes Oficina de Investigaciones
    {
        path: "/reports/licenses_suscont",
        component: LicensesSuscontContainer,
        auth: authRoles.admin
    },

    // Reporte de Contratistas (DACO)
    {
        path: "/reports/contractors",
        component: ContractorsContainer,
        auth: authRoles.admin
    },

    // Reporte de Hospedajes (DACO)
    {
        path: "/reports/lodgings",
        component: LodgingsContainer,
        auth: authRoles.admin
    },

    // Reporte de Defunciones (Registro Demográfico)
    {
        path: "/reports/deaths",
        component: DeathsContainer,
        auth: authRoles.admin
    },

    //Estadísticas de Facturas Creadas por Usuarios de Agencia: Supervisor, Inspector
    {
        path: "/reports/invoices_stadistics",
        component: SummaryInvoiceCreateUserContainer,
        auth: authRoles.admin
    },

]

export default reportsRoutes