import Mock from '../mock';

const categoryCauseNatureClaimsDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre 1",
            tipoEntidad: "Tipo Entidad 1",
            agencia: "Agencia 1",
            orden: 1,
            visible: "si",
        }
    ]
};

Mock.onGet('/api/categoryCauseNatureClaims/all').reply(config => {
    return [200, categoryCauseNatureClaimsDB.list]
});