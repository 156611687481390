import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const BankDepositsContainer = lazy(() =>
  import("./bankDeposits/bankDepositsContainer")
);
const BankDepositsPFContainer = lazy(() =>
  import("./bankDepositsPF/bankDepositsPFContainer")
);
const BankDepositsBatchesContainer = lazy(() =>
  import("./bankDepositsBatches/bankDepositsBatchesContainer")
);

const salesRoutes = [
  {
    path: "/bank_deposits/:batch",
    component: BankDepositsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/bank_deposits",
    component: BankDepositsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/bank_deposits_pf",
    component: BankDepositsPFContainer,
    auth: authRoles.admin,
  },
  {
    path: "/bank_deposits_batches",
    component: BankDepositsBatchesContainer,
    auth: authRoles.admin,
  },
];

export default salesRoutes;
