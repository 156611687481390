import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const equipmentsServicesDB = {
    list: [
        {
            id: shortId.generate(),
            accionTomada: "Aprobado",
            acciones: "x",
            aprobada: true,
            tipoEquipo: "Balanzas /",
            manufactureroNumeroModelo: "Equipo 1",
            numeroSerie: "Dato no definido",
            capacidad: "Dato no definido",
            tolerancia: "Dato no definido",
            ubicacionLocalidad: "Dato no definido",
            numeroPrecinto: "Dato no definido",
            numeroSello: "Dato no definido",
            fechaSello: new Date().getDate().toLocaleString(),
            empresaSucursal: "Emp01 Inc. Casa Matriz (Canóvanas)",
            observaciones: "X FAKE",
        },
        {
            id: shortId.generate(),
            accionTomada: "Confiscado",
            acciones: "x",
            aprobada: true,
            tipoEquipo: "Balanzas / Balanza de Mostrador",
            manufactureroNumeroModelo: "Equipo 1",
            numeroSerie: "Dato no definido",
            capacidad: "Dato no definido",
            tolerancia: "Dato no definido",
            ubicacionLocalidad: "Dato no definido",
            numeroPrecinto: "Dato no definido",
            numeroSello: "Dato no definido",
            fechaSello: new Date().getDate().toLocaleString(),
            empresaSucursal: "Emp01 Inc. Casa Matriz (Canóvanas)",
            observaciones: "X FAKE",
        },
    ],
};

Mock.onGet('/api/equipmentsServices/all').reply(config => {
    return [200, equipmentsServicesDB.list]
});