import Mock from '../mock';

const typesEquipmentWeightsMeasuresDB = {
    list: [
        {
            id: "1",
            nombre: "Equipamento 1",
            duracion: "2 años",
            categoriaEquipo: "Categoria A",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/typesEquipmentWeightsMeasures/all').reply(config => {
    return [200, typesEquipmentWeightsMeasuresDB.list]
});