import Mock from "../mock";

var incremental = 0;

const visitDB = {
    visitList: [
        {
            id: incremental++,
            razon: "asdada",
            mostrarCliente: "Si",
            fVisita: new Date().toLocaleDateString(),
            tipoVisita: "Solicitud",
            estado: "Pendiente",
            resolucionVisita: "DEMO",
        },
        {
            id: incremental++,
            razon: "DEMOOOOOOOOOO",
            mostrarCliente: "No",
            fVisita: new Date().toLocaleDateString(),
            tipoVisita: "Solicitud",
            estado: "Pendiente",
            resolucionVisita: "DEMO",
        },
        {
            id: incremental++,
            razon: "PRUEBAAAAAAAAA",
            mostrarCliente: "Si",
            fVisita: new Date().toLocaleDateString(),
            tipoVisita: "Solicitud",
            estado: "Pendiente",
            resolucionVisita: "DEMO",
        },
    ],
    visitsDocumentList: [
        {
            nombre: '499 Hoja de Inspección de Alimentos (03-09-2023 15:34)',
            categoria: 'Hojas de Inspección',
            fecha: new Date().toLocaleDateString(),
            visita: "DEMOOOOO",
        }
    ],
}

Mock.onGet('/api/visitsRequests/all').reply(config => {
    return [200, visitDB.visitList]
});
Mock.onGet('/api/visitsDocument/all').reply(config => {
    return [200, visitDB.visitsDocumentList]
});
