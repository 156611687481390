import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const radiationMachinesServicesDB = {
    list: [
        {
            id: shortId.generate(),
            aprobada: true,
            tipoMaquina: "CT Scan",
            proposito: "Uso en Humano - Estudios Terapéuticos",
            fijaMovil: "Móvil",
            manufactureroModelo: "ctscan238932y98",
            empresa: "Emp01 Inc.",
            sucursal: "Casa Matriz (Canóvanas)",
            localidadUbicacion: "Bdjd",
            observaciones: "X FAKE",
        },
        {
            id: shortId.generate(),
            aprobada: true,
            tipoMaquina: "CT Scan",
            proposito: "Uso para Investigación Científica - Académico",
            fijaMovil: "Móvil",
            manufactureroModelo: "Ctscan djsy28",
            empresa: "Emp01 Inc.",
            sucursal: "Casa Matriz (Canóvanas)",
            localidadUbicacion: "Bdjd",
            observaciones: "X FAKE",
        },
    ],
};

Mock.onGet('/api/radiationMachinesServices/all').reply(config => {
    return [200, radiationMachinesServicesDB.list]
});