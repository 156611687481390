import Mock from '../mock';

var incremental = 0;

const overdueRequestsDB = {
    list: [
        {
            idSolicitud: incremental++,
            agencia: "DACO " + incremental++,
            servicio: "Urbanizador " + incremental++,
            tipoCliente: "Empresa " + incremental++,
            nombreCliente: "Arturo Vidal" + incremental++,
            nombreEmpresa: "EMPRESAKDMFDK " + incremental++,
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
        {
            idSolicitud: incremental++,
            agencia: "DACO " + incremental++,
            servicio: "Urbanizador " + incremental++,
            tipoCliente: "Empresa " + incremental++,
            nombreCliente: "Arturo Vidal" + incremental++,
            nombreEmpresa: "EMPRESAKDMFDK " + incremental++,
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
    ]
};

Mock.onGet('/api/overdueRequests/all').reply(config => {
    return [200, overdueRequestsDB.list]
});