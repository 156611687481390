import Mock from '../mock';
import shortId from "shortid";

var date = new Date();
var incremental = 0;

const articlesDB = {
    list: [
        {
            id: shortId.generate(),
            numeroBulto: "Bulto" + incremental++,
            unidad: "a",
            tamano: "b",
            nombreArticulo: "4-CN-CUMYL-BUTINACA (1-(4-cyanobutyl)-N-(2-phenylpropan-2-yl)-1 H-indazole-3-carboxamide)",
            dosis: incremental++,
            numeroBultosDespachados: "1" + incremental++,
            fechaDespacho: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
        },
        {
            id: shortId.generate(),
            numeroBulto: "Bulto" + incremental++,
            unidad: "a",
            tamano: "b",
            nombreArticulo: "4-CN-CUMYL-BUTINACA (1-(4-cyanobutyl)-N-(2-phenylpropan-2-yl)-1 H-indazole-3-carboxamide)",
            dosis: incremental++,
            numeroBultosDespachados: "1" + incremental++,
            fechaDespacho: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
        },
        {
            id: shortId.generate(),
            numeroBulto: "Bulto" + incremental++,
            unidad: "a",
            tamano: "b",
            nombreArticulo: "4-CN-CUMYL-BUTINACA (1-(4-cyanobutyl)-N-(2-phenylpropan-2-yl)-1 H-indazole-3-carboxamide)",
            dosis: incremental++,
            numeroBultosDespachados: "1" + incremental++,
            fechaDespacho: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
        },
    ]
}

Mock.onGet('/api/articles/all').reply(config => {
    return [200, articlesDB.list]
});