import { lazy } from 'react'
import {authRoles} from "../../auth/authRoles";

const VisitsView = lazy(() => import('./visits/visitsContainer'))
const VisitDetail = lazy(() =>  import('./visits/visitDetail/visitDetailContainer'))
const DocumentHistory = lazy(() => import('./visits/visitDetail/showHistory'))
const Schedule = lazy(() => import('./schedule/scheduleContainer'))

const visitsRoutes = [
    {
        path: "/visits",
        component: VisitsView,
        auth: authRoles.admin
    },
    {
        path: "/visits_detail/:id",
        component: VisitDetail,
        auth: authRoles.admin
    },
    {
        path: "/document_history/:id",
        component: DocumentHistory,
        auth: authRoles.admin
    },
    {
        path: "/visits_schedule",
        component: Schedule,
        auth: authRoles.admin
    },
]

export default visitsRoutes