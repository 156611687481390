
import layout2Settings from "./Layout2/Layout2Settings";

export const GullLayoutSettings = {
  activeLayout: "layout2", // layout1, layout2
  dir: "ltr", // ltr, rtl
  layout2Settings,
  customizer: {
    show: true,
    open: true
  },
  footer: {
    show: true
  }
};
