import { lazy } from "react";

const Signin = lazy(() => import("./Signin"));

const ForgotPassword = lazy(() => import("./ForgotPassword"));

const Error404 = lazy(() => import("./Error"));

const About = lazy(() => import("./landingPages/about"));

const AboutUs = lazy(() => import("./landingPages/aboutUs"));

const InformationSearch = lazy(() =>
  import("./landingPages/information_search")
);

const Contact = lazy(() => import("./landingPages/contact"));
const FindDocument = lazy(() => import("./landingPages/validationDocument"));
const CompanyRegister = lazy(() => import("./publicPages/CompanyRegister"));
const PeoplesRegister = lazy(() => import("./publicPages/PeopleRegister"));
const Crontab = lazy(() => import("./publicPages/crontab.view"));
const CrontabVisitReminder = lazy(() =>
  import("./publicPages/crontabVisitReminder.view")
);
const CrontabVisitCloser = lazy(() =>
  import("./publicPages/crontabVisitCloser.view")
);
const CrontabRenovationReminder = lazy(() =>
  import("./publicPages/crontabRenovationReminder.view")
);
const CrontabUnpaidAditionalInvoices = lazy(() =>
  import("./publicPages/crontabUnpaidAditionalInvoices.view")
);
const CrontabRenovationReminderSeals = lazy(() =>
  import("./publicPages/crontabRenovationReminderSeals.view")
);
const CrontabRequestUpdateExpired = lazy(() =>
  import("./publicPages/crontabRequestUpdateExpired.view")
);
const CrontabRequestStateReminder = lazy(() =>
  import("./publicPages/crontabRequestStateReminder.view")
);
const CrontabResultInspectionRequests = lazy(() =>
  import("./publicPages/crontabResultInspectionRequests.view")
);
const ManagerRegister = lazy(() => import("./publicPages/GestorRegister"));
const SearchServicePage = lazy(() => import("./publicPages/SearService"));
const SearchContractors = lazy(() => import("./publicPages/SearchContractors"));
const SearchLoading = lazy(() => import("./publicPages/searchLoadings"));
const LicensesNtspContainer = lazy(() =>
  import("./publicPages/SearchLicenses_ntsp")
);

// Validate Documents
const RequestsContainer = lazy(() =>
  import("./landingPages/validateDocuments/requestsValidated")
);
const CertificateHealthContainer = lazy(() =>
  import("./landingPages/validateDocuments/certificateHealth")
);
const OrderBooksContainer = lazy(() =>
  import("./landingPages/validateDocuments/orderBooks")
);

// Confirm account
const ConfirmAccount = lazy(() => import("./publicPages/ConfirmRegister"));
const ConfirmUsersAccount = lazy(() =>
  import("./publicPages/ConfirmUsersRegister")
);
const RecoveryPassword = lazy(() => import("./publicPages/RecoveryPassword"));

const sessionsRoutes = [
  {
    path: "/session/signin",
    component: Signin,
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/session/404",
    component: Error404,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/about_us",
    component: AboutUs,
  },
  {
    path: "/information_search",
    component: InformationSearch,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/find_document",
    component: FindDocument,
  },
  {
    path: "/add_company",
    component: CompanyRegister,
  },
  {
    path: "/add_person",
    component: PeoplesRegister,
  },
  {
    path: "/cron/undelivered_documents/:debugMode/:modeGenerateInvoice",
    component: Crontab,
  },
  {
    path: "/cron/undelivered_documents",
    component: Crontab,
  },
  {
    path: "/cron/visit_reminder/:days",
    component: CrontabVisitReminder,
  },
  {
    path: "/cron/visit_reminder",
    component: CrontabVisitReminder,
  },
  {
    path: "/cron/visit_closer",
    component: CrontabVisitCloser,
  },
  {
    path: "/cron/renovation_reminder/:days/:agency_id/:with_sms",
    component: CrontabRenovationReminder,
  },
  {
    path: "/cron/renovation_reminder/:days/:agency_id",
    component: CrontabRenovationReminder,
  },
  {
    path: "/cron/renovation_reminder/:days",
    component: CrontabRenovationReminder,
  },
  {
    path: "/cron/renovation_reminder",
    component: CrontabRenovationReminder,
  },
  {
    path: "/cron/renovation_reminder_seals/:days/:with_sms",
    component: CrontabRenovationReminderSeals,
  },
  {
    path: "/cron/renovation_reminder_seals/:days",
    component: CrontabRenovationReminderSeals,
  },
  {
    path: "/cron/renovation_reminder_seals",
    component: CrontabRenovationReminderSeals,
  },
  {
    path: "/cron/request_update_expired/:agency_id/:prod",
    component: CrontabRequestUpdateExpired,
  },
  {
    path: "/cron/request_update_expired/:agency_id",
    component: CrontabRequestUpdateExpired,
  },
  {
    path: "/cron/request_update_expired",
    component: CrontabRequestUpdateExpired,
  },
  {
    path: "/cron/request_state_reminder/:state/:days",
    component: CrontabRequestStateReminder,
  },
  {
    path: "/cron/request_state_reminder/:state",
    component: CrontabRequestStateReminder,
  },
  {
    path: "/cron/request_state_reminder",
    component: CrontabRequestStateReminder,
  },
  {
    path: "/cron/result_inspection_requests/:days",
    component: CrontabResultInspectionRequests,
  },
  {
    path: "/cron/result_inspection_requests",
    component: CrontabResultInspectionRequests,
  },
  {
    path: "/cron/unpaid_aditional_invoices/:days/:agency_id/:payment_plan/:with_sms",
    component: CrontabUnpaidAditionalInvoices,
  },
  {
    path: "/cron/unpaid_aditional_invoices/:days/:agency_id/:payment_plan",
    component: CrontabUnpaidAditionalInvoices,
  },
  {
    path: "/cron/unpaid_aditional_invoices/:days/:agency_id",
    component: CrontabUnpaidAditionalInvoices,
  },
  {
    path: "/cron/unpaid_aditional_invoices/:days",
    component: CrontabUnpaidAditionalInvoices,
  },
  {
    path: "/cron/unpaid_aditional_invoices",
    component: CrontabUnpaidAditionalInvoices,
  },
  {
    path: "/confirm/:guid/:encrypt/:email",
    component: ConfirmAccount,
  },
  {
    path: "/confirm_users/:guid",
    component: ConfirmUsersAccount,
  },
  {
    path: "/recovery_pass/:guid/:table/:id",
    component: RecoveryPassword,
  },
  {
    path: "/add_manager",
    component: ManagerRegister,
  },
  {
    path: "/sear_service_public",
    component: SearchServicePage,
  },
  {
    path: "/sear_contractor_public",
    component: SearchContractors,
  },
  {
    path: "/search_report_lodgings",
    component: SearchLoading,
  },
  {
    path: "/search_licenses_nts",
    component: LicensesNtspContainer,
  },
  {
    path: "/requests_validated/:id",
    component: RequestsContainer,
  },
  {
    path: "/certificate_health/:id/:serial",
    component: CertificateHealthContainer,
  },
  {
    path: "/order_books/:serial",
    component: OrderBooksContainer,
  },
];

export default sessionsRoutes;
