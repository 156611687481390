import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const ticketsDB = {
    list: [
        {
            id: shortId.generate(),
            idInfraccion: incremental++,
            servicio: "Hoja de Intervención | STMP",
            numeroIntervencion: incremental++,
            direccionIntervencion: "FAKE CRA" + incremental++,
            hallazgos: incremental++,
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            cliente: "Rep emp01 demo demo",
            empresa: "emp02",
            sucursal: "Casa Matriz",
            supervisor: "Richard Walker F.",
            inspector: "Arturo Vidal",
        },
        {
            id: shortId.generate(),
            idInfraccion: incremental++,
            servicio: "Hoja de Intervención | STMP",
            numeroIntervencion: incremental++,
            direccionIntervencion: "FAKE CRA" + incremental++,
            hallazgos: incremental++,
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            cliente: "Rep emp01 demo demo",
            empresa: "emp02",
            sucursal: "Casa Matriz",
            supervisor: "Richard Walker F.",
            inspector: "Arturo Vidal",
        },
    ],
};

Mock.onGet('/api/tickets/all').reply(config => {
    return [200, ticketsDB.list]
});