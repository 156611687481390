import Mock from "../mock";

const agenciesAssociatedDB = {
    list: [
        {
            acciones: "-",
            id: 1,
            nombre: "Arturo Vidal",
        },
        {
            acciones: "-",
            id: 2,
            nombre: "Alexis Sanches",
        },
    ]
}

Mock.onGet('/api/agenciesAssociated/all').reply(config => {
    return [200, agenciesAssociatedDB.list]
});