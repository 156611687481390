import Mock from '../mock';


const cityDB = {
    list: [
        {
            id: "1",
            nombre: "Ciudad 1",
            pais: "Puerto Rico",
            estado: "Estado 1",
            visible: "si",
        }
    ]
}

Mock.onGet('/api/city/all').reply(config => {
    return [200, cityDB.list]
});