import Mock from '../mock';

const typesResolutionsVisitingStatusDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre",
            agencia: "Salud Ambiental",
            color: "Amarillo",
            visible: "si",
        }
    ]
}

Mock.onGet('/api/typesResolutionsVisitingStatus/all').reply(config => {
    return [200, typesResolutionsVisitingStatusDB.list]
});