import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Dashboard = lazy(() => import("./dashboard/dashboard"));
const Tramite = lazy(() => import("./tramite/index"));

const dashboardRoutes = [
  {
    path: "/dashboard/",
    component: Dashboard,
    auth: authRoles.admin
  },
  {
    path: "/tramite",
    component: Tramite,
    auth: authRoles.admin
  }
];

export default dashboardRoutes;
