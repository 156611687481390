import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const pastRequestsDB = {
    list: [
        {
            id: shortId.generate(),
            servicio: "Hoja de Intervención | Almacén",
            vehiculo: "N/A",
            empresa: "Emp01 Inc.",
            sucursal: "suc emp01",
            fechaSolicitud: new Date().toLocaleDateString() + " " + new Date().getTimezoneOffset().toLocaleString(),
            estado: "Inspección Realizada",
            fechaPago: new Date().toLocaleDateString() + " " + new Date().getTimezoneOffset().toLocaleString(),
            fechaExpedicion: new Date().toLocaleDateString() + " " + new Date().getTimezoneOffset().toLocaleString(),
            fechaRenovacion: new Date().toLocaleDateString() + " " + new Date().getTimezoneOffset().toLocaleString(),
            agencia: "Negociado de Transporte y Otros Servicios Públicos",
        },
    ],
};

Mock.onGet('/api/pastRequests/all').reply(config => {
    return [200, pastRequestsDB.list]
});