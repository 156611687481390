import Mock from '../mock';

var incremental = 0;

const salesDB = {
    list: [
        {
            mes: "Abril",
            servicioOnlineCc: 1500 + incremental++,
            servicioOnlineEc: incremental++,
            totalServiciosOnline: 1500 + incremental++,
            serviciosOffline: incremental++,
            totalServicios: 1500 + incremental++,
            pfCc: 143 + incremental++,
            pfEc: incremental++,
            totalPf: 143 + incremental++,
            totalComisionTresPorciento: 49 + incremental++,
            totalPfReal: 93 + incremental++,
        },
    ]
};

Mock.onGet('/api/sales/all').reply(config => {
    return [200, salesDB.list]
});