import "../fake-db";
import React, { Suspense } from "react";
import "../styles/app/app.scss";
import { Provider } from "react-redux";
import { Router, HashRouter } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";
import routes from "./RootRoutes";
import { Store, persistor } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import { PersistGate } from "redux-persist/integration/react";

function App() {
    return (
        <AppContext.Provider value={{ routes }}>
            <Provider store={Store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Auth>
                        <Suspense fallback={<Loading></Loading>}>
                            <Router history={history}>
                                <HashRouter>
                                    {renderRoutes(RootRoutes)}
                                </HashRouter>
                            </Router>
                        </Suspense>
                    </Auth>
                </PersistGate>
            </Provider>
        </AppContext.Provider>
    );
}

export default App;
