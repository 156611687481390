import Mock from "../mock";

const addClientServiceDB = {

    list: [
        {
            id: 1,
            name: 'Servicio Prueba',
            price: '$0',
        },
    ]
}

Mock.onGet('/api/addClientService/all').reply(config => {
    return [200, addClientServiceDB.list]
});