import Mock from '../mock';

const statesRequestsDB = {
    list: [
        {
            id: "1",
            slug: "Slug 1",
            nombre: "Nombre 1",
            color: "Amarillo",
            agencia: "Agencia 1",
            orden: "1",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/statesRequests/all').reply(config => {
    return [200, statesRequestsDB.list]
});