import Mock from '../mock';

const machinesGeneralsRadiationDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre 1",
            tipo: "Propósito",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/machinesGeneralsRadiation/all').reply(config => {
    return [200, machinesGeneralsRadiationDB.list]
});