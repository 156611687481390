import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

// Constantes de las rutas
const Agencias = lazy(() => import('../agencias/agencia/agencias.view'))
const Dvisiones = lazy(() => import('../agencias/divisiones/divisiones.view'))
const Regiones = lazy(() => import('../agencias/regiones/regiones.view'))
const Officinas = lazy(() => import('../agencias/oficinas/oficinas.view'))

// Rutas
const agenciasRoutes = [
    {
        path: "/agencies/index",
        component: Agencias,
        auth: authRoles.admin
    },
    {
        path: "/agencies/divisions",
        component: Dvisiones,
        auth: authRoles.admin
    },
    {
        path: "/agencies/zones",
        component: Regiones,
        auth: authRoles.admin
    },
    {
        path: "/offices/:id",
        component: Officinas,
        auth: authRoles.admin
    }
];

// Exportar rutas
export default agenciasRoutes;