import Mock from '../mock';


const countriesDB = {
    list: [
        {
            id: "1",
            nombre: "Pais 1",
            Moneda: "PESO",
            nombrePersona: "test test",
            nombreEmpresa: "test test 2",
            proveedeorPago: "sdsfsd",
            visible: "si",
        }
    ]
}

Mock.onGet('/api/countries/all').reply(config => {
    return [200, countriesDB.list]
});