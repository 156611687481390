import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/appContext";
import GullLayout from "app/GullLayout/GullLayout";
import { flatMap } from "lodash";

class AuthGuard extends Component {
  constructor(props, context) {
    super(props);
    let { routes } = context;

    this.state = {
      authenticated: true,
      routes,
      user: null
    };
  }

  componentDidMount() {
    this.setState({
      routes: flatMap(this.state.routes, item => {
        if (item.routes) {
          return [...item.routes];
        }
        return [item];
      })
    });

    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }

    // Se captura el usuario logueado
    const user = JSON.parse(localStorage.getItem("auth_user"));

    // Se valida que exista el usuario
    if (user == null || !user) {

      // Se captura la informacion del usuario
      this.redirectRoute(this.props);
    } else {

      this.setState({
        user: user
      });
    }
  }

  componentDidUpdate() {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.authenticated !== this.state.authenticated;
  }

  static getDerivedStateFromProps(props, state) {

    // Se captura la informacion
    const { location } = props;
    const { pathname } = location;
    const { user } = state;
    const validRoles = user?.roles_system;
    const isAdmin = user?.rol?.id === 1 ? true : false;

    // Se captura el path del usuario
    const nav = state.routes.find(a => a.path === pathname);

    // Se valida cuando viene nav
    if (nav && nav.auth && nav.auth.length) {

      // Se recorren los roles del usuario
      validRoles.map(function (role) {

        // Se valida que no esten los roles dentro del nav
        if (!nav.auth.includes(role)) {

          // Se añade otro elemento a nav
          nav.auth.push(role);
        }
      });
    }

    // Se valida la autenticacion y el mapeo de rutas
    const authenticated = nav && nav.auth && nav.auth.length
      ? nav.auth.includes(user.role)
        ? user.permissions.includes(nav.path)
        : false
      : true;

    // Se retorna el valor
    return {
      authenticated
    };
  }

  redirectRoute(props) {
    const { location, history } = props;
    const { pathname } = location;

    if (pathname.includes("orderBooksInformation") || pathname.includes("requestsInformation") ){
      const regex = pathname.includes("orderBooksInformation") ? /\/orderBooksInformation\/([^\/]+)/ : /\/requestsInformation\/([^\/]+)/;
      // const regex = /\/orderBooksInformation\/([^\/]+)/;
      const match = pathname.match(regex);
      if(match){
        history.push({
          pathname: "/find_document",
          state: { redirectUrl: pathname }
        });
      } else {
        history.push({
          pathname: "/session/signin",
          state: { redirectUrl: pathname }
        });
      }
    } else{
      history.push({
        pathname: "/session/signin",
        state: { redirectUrl: pathname }
      });
    }
  }

  render() {
    let { route } = this.props;
    const { authenticated } = this.state;

    return authenticated ? (
      <Fragment>
        <GullLayout route={route}></GullLayout>
      </Fragment>
    ) : null;
  }
}

AuthGuard.contextType = AppContext;

const mapStateToProps = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(AuthGuard));
