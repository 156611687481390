import Mock from '../mock';
import shortId from "shortid";

var incremental = 0;

const licensesSuscontDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitud: "4286" + incremental++,
            division: "Registros",
            servicio: "Certificación del Certificado de Registro",
            paramCertificadoEmitidoA: "Demo",
            paramNombreRegistrado: "DEMOOOOOOOOOO",
            paramNumeroRegistroEstatal: "DM-5435-342",
            paramClasificaciones: "2N, 2NN, 3N, 3NN, 4N, 4NN, 5N, 5NN",
            paramProfesion: "Profesion DEMO",
            paramEspecialidad: "Especialidad demo",
            paramDireccion: "917 AVE TITO CASTRO CARR14 C/O",
            paramNumeroLicenciaJuntaProfesionalesSalud: "213213",
            cliente: "Dueño Empresa Dic",
            empresa: "Empr",
            sucursal: "Casa Matriz",
            direccionFisica: "DIR FEAKE 321312",
            direccionPostal: "DIRECCION",
            ciudad: "Santiago",
            region: "Region Cultiva",
            telefono: 43243423,
            paramFechaExpedicion: new Date().toLocaleDateString(),
            paramFechaExpiracion: new Date().toLocaleDateString(),
            solicitudFechaExpedicion: new Date().toLocaleDateString(),
            solicitudFechaExpiracion: new Date().toLocaleDateString()
        },
        {
            id: shortId.generate(),
            numeroSolicitud: "4286" + incremental++,
            division: "Registros",
            servicio: "Certificación del Certificado de Registro",
            paramCertificadoEmitidoA: "Demo",
            paramNombreRegistrado: "DEMOOOOOOOOOO",
            paramNumeroRegistroEstatal: "DM-5435-342",
            paramClasificaciones: "2N, 2NN, 3N, 3NN, 4N, 4NN, 5N, 5NN",
            paramProfesion: "Profesion DEMO",
            paramEspecialidad: "Especialidad demo",
            paramDireccion: "917 AVE TITO CASTRO CARR14 C/O",
            paramNumeroLicenciaJuntaProfesionalesSalud: "213213",
            cliente: "Dueño Empresa Dic",
            empresa: "Empr",
            sucursal: "Casa Matriz",
            direccionFisica: "DIR FEAKE 321312",
            direccionPostal: "DIRECCION",
            ciudad: "Santiago",
            region: "Region Cultiva",
            telefono: 43243423,
            paramFechaExpedicion: new Date().toLocaleDateString(),
            paramFechaExpiracion: new Date().toLocaleDateString(),
            solicitudFechaExpedicion: new Date().toLocaleDateString(),
            solicitudFechaExpiracion: new Date().toLocaleDateString()
        },
        {
            id: shortId.generate(),
            numeroSolicitud: "4286" + incremental++,
            division: "Registros",
            servicio: "Certificación del Certificado de Registro",
            paramCertificadoEmitidoA: "Demo",
            paramNombreRegistrado: "DEMOOOOOOOOOO",
            paramNumeroRegistroEstatal: "DM-5435-342",
            paramClasificaciones: "2N, 2NN, 3N, 3NN, 4N, 4NN, 5N, 5NN",
            paramProfesion: "Profesion DEMO",
            paramEspecialidad: "Especialidad demo",
            paramDireccion: "917 AVE TITO CASTRO CARR14 C/O",
            paramNumeroLicenciaJuntaProfesionalesSalud: "213213",
            cliente: "Dueño Empresa Dic",
            empresa: "Empr",
            sucursal: "Casa Matriz",
            direccionFisica: "DIR FEAKE 321312",
            direccionPostal: "DIRECCION",
            ciudad: "Santiago",
            region: "Region Cultiva",
            telefono: 43243423,
            paramFechaExpedicion: new Date().toLocaleDateString(),
            paramFechaExpiracion: new Date().toLocaleDateString(),
            solicitudFechaExpedicion: new Date().toLocaleDateString(),
            solicitudFechaExpiracion: new Date().toLocaleDateString()
        },
    ]
};

Mock.onGet('/api/licensesSuscont/all').reply(config => {
    return [200, licensesSuscontDB.list]
});