import Mock from '../mock';

const parametersDB = {
    list: [
        {
            id: "1",
            nombre: "Parametro 1",
            valor: "Valor 1",
            descripcion: "Descripcion 1",
            visible: "si",
        }
    ]
}

Mock.onGet('/api/parameters/all').reply(config => {
    return [200, parametersDB.list]
});