import Mock from '../mock';

const categoryDocumentsDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre 1",
            tipoEntidad: "Tipo Entidad 1",
            agencia: "Agencia 1",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/categoryDocuments/all').reply(config => {
    return [200, categoryDocumentsDB.list]
});