import { lazy } from 'react'
import {authRoles} from "../../auth/authRoles";

const Users = lazy(() => import('./usuarios.view'))
const detailUser = lazy(() => import('./detalleUsuario'))

const usersRoutes = [
    {
        path: "/users",
        component: Users,
        auth: authRoles.admin
    },
    {
        path: "/detail_users/:idUser/:section?",
        component: detailUser,
        auth: authRoles.admin
    }
]

export default usersRoutes