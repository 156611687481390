import Mock from "../mock";


const messagesDB = {
    recived: [
        {
            id: '1',
            sender: 'Sistema Renovaciones Online ',
            contactRol: 'Sistema',
            agencia: '',
            asunto: 'Nuevo mensaje de Inspector Demo Demo 2 (Persona... ',
            fecha: '03/16/2023 15:30 ',
        },{
            id: '2',
            sender: 'Sistema Renovaciones Online ',
            contactRol: 'Sistema',
            agencia: '',
            asunto: 'Nuevo mensaje de Inspector Demo Demo 2 (Persona... ',
            fecha: '03/16/2023 15:30 ',
        },
    ],
    sent: [
        {
            id: '1',
            to: 'Sistema Renovaciones Online ',
            contactRol: 'Sistema',
            agencia: '',
            asunto: 'Nuevo mensaje de Inspector Demo Demo 2 (Persona... ',
            fecha: '03/16/2023 15:30 ',
        },{
            id: '2',
            to: 'Sistema Renovaciones Online ',
            contactRol: 'Sistema',
            agencia: '',
            asunto: 'Nuevo mensaje de Inspector Demo Demo 2 (Persona... ',
            fecha: '03/16/2023 15:30 ',
        },
    ],
    favorite: [
        {
            id: '1',
            from: 'Sistema Renovaciones Online ',
            to: 'Pedro Ramirez C. A. ',
            asunto: 'otro para gestor demo',
            fecha: '03/16/2023 15:30 ',
        },{
            id: '2',
            from: 'Sistema Renovaciones Online ',
            to: 'Gestor 2 Demo Demo ',
            asunto: 'Probando Contador Nuevo ',
            fecha: '03/16/2023 15:30 ',
        },
    ],
    contacts: [
        {
            id: '1',
            name: 'Cliente Empresa Demo',
            rol: 'Empresa',
        },{
            id: '2',
            name: 'Manuel Guerra-Mondragón G',
            rol: 'Empresa ',
        },
    ],
}

Mock.onGet('/api/messages/recived').reply(config => {
    return [200, messagesDB.recived]
});
Mock.onGet('/api/messages/sent').reply(config => {
    return [200, messagesDB.sent]
});
Mock.onGet('/api/messages/favorite').reply(config => {
    return [200, messagesDB.favorite]
});
Mock.onGet('/api/messages/contacts').reply(config => {
    return [200, messagesDB.contacts]
});