import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const billsDB = {
    list: [
        {
            id: shortId.generate(),
            folio: "623" + incremental++,
            descripcion: "Factura Hoja de Intervención | Almacén",
            fechaFactura: new Date().toLocaleDateString(),
            fechaConfirmacionPago: new Date().toLocaleDateString(),
            fechaVencimiento: new Date().toLocaleDateString(),
            fechaPago: new Date().toLocaleDateString(),
            tipo: "Servicio",
            estado: "Pagada",
            tieneMulta: "Activo",
            precio: "$0.00",
            pf: "$0.00",
            descuento: "$0.00",
            total: "$0.00",
        },
        {
            id: shortId.generate(),
            folio: "623" + incremental++,
            descripcion: "Factura de Boleto #41",
            fechaFactura: new Date().toLocaleDateString(),
            fechaConfirmacionPago: new Date().toLocaleDateString(),
            fechaVencimiento: new Date().toLocaleDateString(),
            fechaPago: new Date().toLocaleDateString(),
            tipo: "Boleto",
            estado: "No Pagada",
            tieneMulta: "Activo",
            precio: "$4,000.00",
            pf: "$0.00",
            descuento: "$0.00",
            total: "$4,000.00",
        },
    ],
};

Mock.onGet('/api/bills/all').reply(config => {
    return [200, billsDB.list]
});