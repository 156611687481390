import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const details_ticketsDB = {
    list: [
        {
            id: shortId.generate(),
            categoria: "Autorizaciones (A-1)",
            numero: "A-1.0" + incremental++,
            infraccion: "Servicio No Autorizado",
            descripcion: "Dedicarse a prestar, ofrecer o promocionarse para ofrecer servicios públicos mediante paga sin haber previamente solicitado y obtenido la correspondiente autorización del NTSP o con la autorización vencida por seis (6) meses o más. Esta infracción incluye transitar por las vías públicas con una tablilla pública instalada en la unidad y con la autorización vencida por seis (6) meses o más.",
            monto: incremental++,
        },
        {
            id: shortId.generate(),
            categoria: "Autorizaciones (A-1)",
            numero: "A-1.0" + incremental++,
            infraccion: "Servicio No Autorizado",
            descripcion: "Prestar un servicio público diferente al autorizado por el NTSP.",
            monto: incremental++,
        },
    ],
    historyTicket: [
        {
            id: shortId.generate(),
            usuario: "Richard Walker F.",
            rol: "Oficial Autorizado",
            fecha: new Date().toLocaleDateString() + " " + new Date().getTime().toLocaleString(),
            operacion: "Creado"
        },
        {
            id: shortId.generate(),
            usuario: "Administrador Renovaciones Online MASTER",
            rol: "Administrador Master",
            fecha: new Date().toLocaleDateString() + " " + new Date().getTime().toLocaleString(),
            operacion: "¿Tiene una Solicitud de Recurso de Revisión de Boleto?: Si",
        },
    ],
    allowedUsers: [
        {
            idUsuario: incremental++,
            usuario: "Richard Walker F.",
            emailUsuario: "kmkfdsmfk@gmail.com",
            rol: "Oficial Autorizado",
            permitidoDesde: new Date().toLocaleDateString() + " " + new Date().getTime().toLocaleString(),
            permitidoPor: new Date().toLocaleDateString() + " " + new Date().getTime().toLocaleString(),
        },
    ],
};

Mock.onGet('/api/details_tickets/all').reply(config => {
    return [200, details_ticketsDB.list]
});

Mock.onGet('/api/history_tickets/all').reply(config => {
    return [200, details_ticketsDB.historyTicket]
});

Mock.onGet('/api/allowed_users/all').reply(config => {
    return [200, details_ticketsDB.allowedUsers]
});