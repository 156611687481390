import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";


const SearchServiceView = lazy(() => import('./searchServiceContainer'))
const SearchServiceClientView = lazy(() => import('./searchServiceContainerClient'))
const Cart = lazy(() => import('../cart/index'))
const CartStep = lazy(() => import('../cart/steps/step2'))
const DocumentStep = lazy(() => import('../cart/steps/step3'))
const BuyCartStep = lazy(() => import('../cart/steps/step4'))
const CartClient = lazy(() => import('../cart/indexClient'))
const CartClientEquipments = lazy(() => import('../cart/stepsClients/clientServicesStep1'));
const CartClientParams = lazy(() => import('../cart/stepsClients/clientServicesStep2'));
const CartClientDocuments = lazy(() => import('../cart/stepsClients/clientServicesStep3'));
const CartParams = lazy(() => import('../cart/indexParams'))
const detailsFileViewCompletePagesByDocumentRequired = lazy(() => import('../cart/pages/detailsFileViewCompletePagesByDocumentRequired'))
const detailsFileViewCompletePagesByDocumentRequiredClient = lazy(() => import('../cart/pages/detailsFileViewCompletePagesByDocumentRequiredClient'))

const SearchServiceRoutes = [
    {
        path: "/complete_formulary_by_document_required/:id/:idDocumentRequired/:shoppingCartId",
        component: detailsFileViewCompletePagesByDocumentRequired,
        auth: authRoles.admin
    },
    {
        path: "/complete_formulary_by_document_required_client/:id/:idDocumentRequired",
        component: detailsFileViewCompletePagesByDocumentRequiredClient,
        auth: authRoles.admin
    },
    {
        path: "/search_services",
        component: SearchServiceView,
        auth: authRoles.admin
    },
    {
        path: "/cart/:step",
        component: Cart,
        auth: authRoles.admin
    },
    {
        path: "/param_buy_carts/:step",
        component: CartStep,
        auth: authRoles.admin
    },
    {
        path: "/documents_buy_cart/:step",
        component: DocumentStep,
        auth: authRoles.admin
    },
    {
        path: "/pay_cart_send/:step",
        component: BuyCartStep,
        auth: authRoles.admin
    },
    {
        path: "/search_services_client/:id",
        component: SearchServiceClientView,
        auth: authRoles.admin
    },
    {
        path: "/cart_client/:requestId/equipments",
        component: CartClientEquipments,
        auth: authRoles.admin
    },
    {
        path: "/cart_client/:requestId/param_buy_carts",
        component: CartClientParams,
        auth: authRoles.admin
    },
    {
        path: "/cart_client/:requestId/documents_buy_cart",
        component: CartClientDocuments,
        auth: authRoles.admin
    },
    {
        path: "/cart_options/:id/:name_path",
        component: CartParams,
        auth: authRoles.admin
    },
]

export default SearchServiceRoutes