import Mock from "../mock";

var incremental = 0;

const equipmentTypeApprovalDB = {

    list: [
        {
            id: incremental++,
            tipo: "tipo " + incremental++,
            marca: "marca " + incremental++,
            modeloModelos: "modelo" + incremental++,
            capacidadDivisiones: "capacidad " + incremental++,
            clasificacion: "clasificacion " + incremental++,
            certificadoNumero: "certificadoNumero " + incremental++,
            certificadoConformidad: "certificadoConformidad " + incremental++,
            fechaEstado: new Date().toLocaleDateString(),
            estado: "estado " + incremental++,
            activo: "Si",
        },
        {
            id: incremental++,
            tipo: "tipo " + incremental++,
            marca: "marca " + incremental++,
            modeloModelos: "modelo" + incremental++,
            capacidadDivisiones: "capacidad " + incremental++,
            clasificacion: "clasificacion " + incremental++,
            certificadoNumero: "certificadoNumero " + incremental++,
            certificadoConformidad: "certificadoConformidad " + incremental++,
            fechaEstado: new Date().toLocaleDateString(),
            estado: "estado " + incremental++,
            activo: "No",
        },
    ]
}

Mock.onGet('/api/equipmentTypeApproval/all').reply(config => {
    return [200, equipmentTypeApprovalDB.list]
});