import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const medicsDB = {
    list: [
        {
            id: shortId.generate(),
            nombreCompleto: "Sebastian Viera" + incremental++,
        },
        {
            id: shortId.generate(),
            nombreCompleto: "Arturo Vidal" + incremental++,
        },
    ],
};

Mock.onGet('/api/medics/all').reply(config => {
    return [200, medicsDB.list]
});