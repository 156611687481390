import Mock from '../mock';
import shortId from "shortid";

var date = new Date();

const medicinesDB = {
    list: [
        {
            id: shortId.generate(),
            rolUsuarioRegistra: "Admin",
            categoriaMedicamento: "Medicamentos",
            manufactureroMedicamento: "Manuf. demo 1",
            distribuidorUsa: "Dist USA Demo 1",
            distribuidorPr: "Dist PR Demo 1",
            agenteRepresentante: "FAKE",
            tipoUso: "Uso en Humanos",
            nombreGenerico: "Demo generico 13 diciembre",
            nombreComercial: "comercial demo",
            empresa: "Emp01 Inc.",
            sucursal: "Casa Matriz",
            ndc: "12939-23-123l ",
            fechaRegistroFda: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
        }
    ]
};

Mock.onGet('/api/medicines/all').reply(config => {
    return [200, medicinesDB.list]
});