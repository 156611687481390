import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const RecoverOrderContainer = lazy(() => import('./recoverOrder/recoverOrderContainer'))
const EChecksContainer = lazy(() => import('./eChecks/eChecksContainer'))
const RestoreSaleContainer = lazy(() => import('./RestoreSaleOrder/summaryRestoreSaleOrder'))
const MoveCompanyContainer = lazy(() => import('./moveCompany/moveCompanyContainer'))
const MoveRequestClientGeneralContainer = lazy(() => import('./moveRequestClientGeneral/moveRequestClientGeneralContainer'))

const toolsRoutes = [
    {
        path: "/tools/recover_order",
        component: RecoverOrderContainer,
        auth: authRoles.admin
    },
    {
        path: "/tools/echecks",
        component: EChecksContainer,
        auth: authRoles.admin
    },
    {
        path: "/tools/move_company",
        component: MoveCompanyContainer,
        auth: authRoles.admin
    },
    {
        path: "/tools/move_request_client_general",
        component: MoveRequestClientGeneralContainer,
        auth: authRoles.admin
    },
    {
        path: "/tools/restore_order",
        component: RestoreSaleContainer,
        auth: authRoles.admin
    }
]


export default toolsRoutes;