import Mock from "../mock";


const inspectionDB = {
    list: [
        {
            id: "1",
            nombre: "Hoja de Inspección de Alimentos",
            nombreCorto: "Hoja Alimentos  ",
            nombreArchivo: "hoja-inspeccion-alimentos-salud",
            agencia: 'Salud Ambiental',
            orden: "1",
        },{
            id: "1",
            nombre: "Hoja de Inspección de No Alimentos",
            nombreCorto: "Hoja No Alimentos",
            nombreArchivo: "hoja-inspeccion-no-alimentos-salud",
            agencia: 'Salud Ambiental',
            orden: "2",
        },
    ]
}


Mock.onGet("/api/inspectionTypes/all").reply(config => {
    const response = inspectionDB.list;
    return [200, response];
});