import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const tempBuilderDB = {
    list: [
        {
            id: shortId.generate(),
            nombre: "Arturo Vidal",
            agencia: "Agencia 1",
            idioma: "Ingles",
            orientacion: "Horizontal",
            visible: "No",
        },
        {
            id: shortId.generate(),
            nombre: "CR7",
            agencia: "Agencia 2",
            idioma: "Español",
            orientacion: "Vertical",
            visible: "Si",
        },
    ],
};

Mock.onGet('/api/tempBuilder/all').reply(config => {
    return [200, tempBuilderDB.list]
});