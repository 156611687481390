import Mock from '../mock';


const agenciasDB =  {
    divisiones: [
        {
            'id': 1,
            'slug': 'salud-radiologica ',
            'nombre': 'Ingeniería Sanitaria',
            'visible': 'No',
            'departamento': 'Salud Ambiental',
            'subdivisiones': '5'
        },
        {
            'id': 2,
            'slug': 'salud-radiologica ',
            'nombre': 'Higienización del Ambiente Físico Inmediato',
            'visible': 'No',
            'nombreingles': '',
            'departamento': 'Salud Ambiental',
            'subdivisiones': '2'
        }
    ],
    regiones: [
        {
            'id': 1,
            'slug': 'salud-radiologica ',
            'nombre': 'Arecibo',
            'agencia': 'Salud Ambiental',
        },
        {
            'id': 2,
            'slug': 'salud-radiologica ',
            'nombre': 'Bayamón',
            'agencia': 'Salud Ambiental',
        },
        {
            'id': 3,
            'slug': 'salud-radiologica ',
            'nombre': 'Caguas',
            'agencia': 'Salud Ambiental',
        },
        {
            'id': 4,
            'slug': 'salud-radiologica ',
            'nombre': 'Fajardo',
            'agencia': 'Salud Ambiental',
        },
        {
            'id': 5,
            'slug': 'salud-radiologica ',
            'nombre': 'Mayagüez ',
            'agencia': 'Salud Ambiental',
        }
    ],
    oficinas : [
        {
            "id": 1,
            "nombreEncargado": "Nombr de prueba",
            "email": "test1@gmail.com",
            "telefono": "233323",
            "extTelefono": "22",
            "direccion": "dir",
            "horario": "hr",
            "ciudad": "Martin",
            "condado": "ss"
        },
        {
            "id": 2,
            "nombreEncargado": "name",
            "email": "test@gmail.com",
            "telefono": "123456",
            "extTelefono": "222",
            "direccion": "Dirección de prueba",
            "horario": "diur",
            "ciudad": "Vega Baja",
            "condado": "nn"
        },
    ]
}



Mock.onGet('/api/agencias/divisiones').reply(config => {
    return [200, agenciasDB.divisiones]
});

Mock.onGet('/api/agencias/regiones').reply(config => {
    return [200, agenciasDB.regiones]
});
Mock.onGet('/api/agencias/oficinas').reply(config => {
    return [200, agenciasDB.oficinas]
});
