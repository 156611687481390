import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const patientsDB = {
    list: [
        {
            id: shortId.generate(),
            nombreCompleto: "Sebastian Viera" + incremental++,
            email: "idkasdkms@gmail.com",
            ciudad: "Ciudad " + incremental++,
            fechaRegistro: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/patients/all').reply(config => {
    return [200, patientsDB.list]
});