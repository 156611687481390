import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const historyInspectionDB = {
    list: [
        {
            id: shortId.generate(),
            estado: "Pendiente",
            notasMotivo: "mgfdkgmkfdgfdmgkfdkgkdfmgkdf",
            cambiadoPor: "Sistema Renovaciones Online",
            rol: "Sistema",
            fecha: new Date().toLocaleDateString(),
        },
        {
            id: shortId.generate(),
            estado: "En Ejecución",
            notasMotivo: "mgfdkgmkfdgfdmgkfdkgkdfmgkdf",
            cambiadoPor: "Richard Walker F.",
            rol: "Oficial Autorizado",
            fecha: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/historyInspection/all').reply(config => {
    return [200, historyInspectionDB.list]
});