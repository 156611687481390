import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const formBuilderDB = {
    list: [
        {
            id: shortId.generate(),
            nombrePrivado: "Arturo Vidal",
            nombrePublico: "Arturo 'King' Vidal",
            tipoEntidad: "Documentos Requeridos",
            contenido: "Vacio",
            idioma: "Español",
            visible: "Si",
        },
        {
            id: shortId.generate(),
            nombrePrivado: "Arturo Vidal",
            nombrePublico: "Arturo 'King' Vidal",
            tipoEntidad: "Hojas de Inspección",
            contenido: "Vacio",
            idioma: "Español",
            visible: "Si",
        },
    ],
};

Mock.onGet('/api/formBuilder/all').reply(config => {
    return [200, formBuilderDB.list]
});