import axios from "axios";

// Se inicializa el array de rutas predeterminadas para tomar en cuenta la ruta de produccion
const routesPredefined = [
  "http://10.0.2.20",
  "http://localhost:3000",
  "http://renovonlinedev.com",
];

// Se valida si la ruta de produccion esta en el array de rutas predeterminadas
const ruta = routesPredefined.includes(window.location.origin)
  ? process.env.REACT_APP_API_BASE_URL_MODULES
  : window.location.origin + "/api/v1/modules";

// Se instancia el servicio
const instanceModules = axios.create({
  baseURL: ruta,
  //baseURL: "http://127.0.0.1:9007/api/v1/modules",
});

// Se agrega el token a la cabecera
instanceModules.interceptors.request.use(
  // Se valida que haya token
  (config) => {
    // Se obtiene el token
    const token = localStorage.getItem("jwt_token");

    // Se setea el token en la cabecera
    const auth = token ? `Bearer ${token}` : "";

    // Se setea el token en la cabecera
    config.headers.common["Authorization"] = auth;

    // Se obtiene la URL completa
    const fullUrl = window.location.href;
    config.headers.common["X-Full-Url"] = fullUrl;

    // Se obtiene el ID del usuario y el tipo de usuario desde localStorage
    const userId = JSON.parse(localStorage.getItem("auth_user"))?.id;
    const userType = localStorage.getItem("auth_user_type");

    // Se añaden el ID del usuario y el tipo de usuario a los headers
    if (userId) {
      config.headers.common["X-User-Id"] = userId;
    }
    if (userType) {
      config.headers.common["X-User-Type"] = userType?.toLowerCase();
    }

    // Se retorna la configuracion
    return config;
  },
  (error) => Promise.reject(error)
);

// Se exporta el servicio
export { instanceModules };
