import Mock from "../mock";

const companiesAssociatedDB = {

    list: [
        {
            acciones: "-",
            id: 1,
            nombre: "Arturo Vidal",
            ciudad: "Santiago",
            telefono: "43432432",
            celular: "569337483",
        },
        {
            acciones: "-",
            id: 2,
            nombre: "Alexis Sanches",
            ciudad: "Santiago",
            telefono: "43432432",
            celular: "569337483",
        },
    ]
}

Mock.onGet('/api/companiesAssociated/all').reply(config => {
    return [200, companiesAssociatedDB.list]
});