import Mock from '../mock';

const categoryInfractionsDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre 1",
            agencia: "Agencia a",
            orden: "1",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/categoryInfractions/all').reply(config => {
    return [200, categoryInfractionsDB.list]
});