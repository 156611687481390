import Mock from '../mock';

const typesRadiationMachinesDB = {
    list: [
        {
            id: "1",
            nombre: "Máquina 1",
            tipoMaquina: "Radiación",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/typesRadiationMachines/all').reply(config => {
    return [200, typesRadiationMachinesDB.list]
});