import Mock from "../mock";

const clientsCompaniesDB = {

    list: [
        {
            acciones: "-",
            id: 1,
            tipoRepresentante: "Dueño",
            nombre: "Arturo",
            apellidoPaterno: "Vidal",
            apellidoMaterno: "Sanches",
        },
        {
            acciones: "-",
            id: 2,
            tipoRepresentante: "Dueño",
            nombre: "Arturo",
            apellidoPaterno: "Vidal",
            apellidoMaterno: "Sanches",
        },
        {
            acciones: "-",
            id: 3,
            tipoRepresentante: "Dueño",
            nombre: "Arturo",
            apellidoPaterno: "Vidal",
            apellidoMaterno: "Sanches",
        },
    ]
}

Mock.onGet('/api/clientsCompanies/all').reply(config => {
    return [200, clientsCompaniesDB.list]
});