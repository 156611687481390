import Mock from '../mock';

const typesVehiclesDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre",
            slug: "Slug 1",
            subTipos: "Pesado",
            categoriaVehiculo: "Carga",
            orden: "1",
            visible: "si",
        }
    ]
};

const subTypesDB = {
    types: [
        {
            'id': 1,
            'nombre': 'Sub tipo 1',
            'slug': 'Slug 1',
            'tipo': ' Tipo 1',
            'orden': 1,
        },
        {
            'id': 2,
            'nombre': 'Sub tipo 2',
            'slug': 'Slug 2',
            'tipo': ' Tipo 2',
            'orden': 2,
        },
    ]
};

Mock.onGet('/api/typesVehicles/all').reply(config => {
    return [200, typesVehiclesDB.list]
});

Mock.onGet('/api/subTypes/all').reply(config => {
    return [200, subTypesDB.types]
});