import localStorageService from "./localStorageService";
import { instanceAgencies } from "app/axios/instanceAgencies";
import { instanceClients } from "app/axios/instanceClients";
import { instanceComplaints } from "app/axios/instanceComplaints";
import { instanceCronjobs } from "app/axios/instanceCronjobs";
import { instanceLogin } from "app/axios/instanceLogin";
import { instanceMaintainers } from "app/axios/instanceMaintainers";
import { instanceMessages } from "app/axios/instanceMessages";
import { instanceModules } from "app/axios/instanceModules";
import { instanceReports } from "app/axios/instanceReports";
import { instanceRequests } from "app/axios/instanceRequests";
import { instanceServices } from "app/axios/instanceServices";
import { instanceShoppings } from "app/axios/instanceShopping";
import { instanceUsers } from "app/axios/instanceUsers";
import Cookies from 'js-cookie';

// Se inicializa la clase
class JwtAuthService {

  // Metodo encargado de logear al usuario
  loginWithEmailAndPassword = (data) => {

    // Se retorna una promesa con axios
    return instanceLogin.post("/login", data).then((r) => {

      // Se valida la respuesta del servicio
      if (r.status === 200) {

        // Se captura la información
        const user = r?.data?.errors;

        // Se setea el token
        this.setSession(user?.token);

        // Se setea el usuario
        this.setUser(user?.data);

        // Se setea el tipo de usuario logeado
        this.typeUser(user?.login_table);

        // Se retorna la data
        localStorageService.setItem("ShowPendingInvoices", true);

        // Se captura la hora actual para setearla en el local storage
        const date = new Date();
        const time = date.getTime();

        // Se setea la hora actual
        localStorageService.setItem("logged_time", time);

        // Se retorna la data
        return r?.data?.errors;
      } else {

        // Se retorna la data
        return r;
      };
    }).catch((x) => {

      // Se inicializan las variables
      const excep = x?.response?.data?.errors;

      // Se valida cuando es un error de red
      if (excep == undefined) {

        // Se manda un mensaje de error
        const error = {
          status: 500,
          detail: x?.message,
          title: "Error Network",
        };

        // Se retorna el error
        return error;
      };

      // Se retorna el error
      return excep;
    });
  };

  // Metodo encargado de cerrar sesion
  logout = () => {

    // Se captura el token del local storage
    const token = localStorage.getItem("jwt_token");

    // Se valida si hay token
    if (token) {

      // Se envia la petición del token para eliminarlo de manera global
      instanceUsers.post("/logout", token);
    };

    // Se remueve el token
    this.setSession(null);

    // Se remueve el usuario
    this.removeUser();

    // Se remueven las cookies
    Cookies.remove('jwt', { path: '/' });

    // Se limpia el local storage
    localStorage.clear();
  };

  // Metodo encargado de setear el token en el local storage
  setSession = (token) => {

    // Se valida que haya token
    if (token) {

      // Se setea el token
      localStorage.setItem("jwt_token", token);

      // Se setea el token en el header
      instanceAgencies.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceMaintainers.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceUsers.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceClients.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceLogin.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceComplaints.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceCronjobs.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceMessages.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceModules.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceReports.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceRequests.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceServices.defaults.headers.common["Authorization"] = "Bearer " + token;
      instanceShoppings.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {

      // Se remueve el token
      localStorage.removeItem("jwt_token");

      // Se remueve el token del header
      delete instanceAgencies.defaults.headers.common["Authorization"];
      delete instanceMaintainers.defaults.headers.common["Authorization"];
      delete instanceUsers.defaults.headers.common["Authorization"];
      delete instanceClients.defaults.headers.common["Authorization"];
      delete instanceLogin.defaults.headers.common["Authorization"];
      delete instanceComplaints.defaults.headers.common["Authorization"];
      delete instanceCronjobs.defaults.headers.common["Authorization"];
      delete instanceMessages.defaults.headers.common["Authorization"];
      delete instanceModules.defaults.headers.common["Authorization"];
      delete instanceReports.defaults.headers.common["Authorization"];
      delete instanceRequests.defaults.headers.common["Authorization"];
      delete instanceServices.defaults.headers.common["Authorization"];
      delete instanceShoppings.defaults.headers.common["Authorization"];

      // Se limpia el local storage
      localStorage.clear();
    };
  };

  // Metodo encargado de setear el usuario en el local storage
  setUser = (user) => {

    // Se setea el usuario
    localStorageService.setItem("auth_user", user);
  };

  // Metodo encargado de setear el tipo de usuario en el local storage
  typeUser = (type) => {

    // Se setea el tipo del usuario que esta logeado
    localStorageService.setItem("auth_user_type", type);
  };

  // Metodo encargado de setear el subusuario en el local storage dentro de auth_user
  setSubUser = (user) => {

    // Se setea el usuario
    localStorage.setItem("ShowPendingInvoices", "true");

    // Se captura el usuario
    const auth_user = localStorageService.getItem("auth_user");

    // Se valida que exista el usuario
    if (auth_user) {

      // Se setea el usuario
      auth_user.sub_user = user;

      // Se setea el usuario
      localStorageService.setItem("auth_user", auth_user);
    };
  };

  // Metodo encargado de remover el usuario del local storage
  removeUser = () => {

    // Se remueve el usuario
    localStorage.removeItem("auth_user");
  };

  // Metodo encargado de remover el subusuario del local storage dentro de auth_user
  removeSubUser = (subUserId) => {

    // Se captura el usuario
    const auth_user = localStorageService.getItem("auth_user");

    // Se valida que exista el usuario
    if (auth_user) {

      // Se valida que exista el subusuario
      if (auth_user.sub_user) {

        // Se valida que el subusuario sea el mismo
        if (auth_user.sub_user.id == subUserId) {

          // Se remueve el subusuario
          delete auth_user.sub_user;

          // Se setea el usuario
          localStorageService.setItem("auth_user", auth_user);
        };
      };
    };
  };
}

// Se exporta una instancia de la clase
export default new JwtAuthService();
