import Mock from '../mock';

const reasonsStatusRequestDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre 1",
            descripcion: "Descripcion 1",
            color: "Amarillo",
            agencia: "Agencia 1",
            estados: "Estado 1",
            orden: "1",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/reasonsStatusRequest/all').reply(config => {
    return [200, reasonsStatusRequestDB.list]
});