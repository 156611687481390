import axios from "axios";

// Se inicializa el array de rutas predeterminadas para tomar en cuenta la ruta de produccion
const routesPredefined = [
  "http://10.0.2.20",
  "http://localhost:3000",
  "http://renovonlinedev.com",
];

// Se valida si la ruta de produccion esta en el array de rutas predeterminadas
const ruta = routesPredefined.includes(window.location.origin)
  ? process.env.REACT_APP_API_BASE_URL_USERS
  : window.location.origin + "/api/v1/users";

// Se instancia el servicio
const instanceLogin = axios.create({
  baseURL: ruta,
  withCredentials: true,
  // baseURL: "http://127.0.0.1:9002/api/v1/users",
});

// Se agrega el token a la cabecera
instanceLogin.interceptors.request.use(

  // Se valida que haya token
  (config) => {

    // Se obtiene el token
    const token = localStorage.getItem("jwt_token");

    // Se setea el token en la cabecera
    const auth = token ? `Bearer ${token}` : "";

    // Se setea el token en la cabecera
    config.headers.common["Authorization"] = auth;

    // Se retorna la configuracion
    return config;
  },
  (error) => Promise.reject(error)
);

// Se exporta el servicio
export { instanceLogin };
