import Mock from "../mock";

const ListDB = {
    list: [
        {
            id: 1,
            email: "uilib@gmail.com",
            userName: "ui lib",
            projectName: "Gull development",
            date: new Date().toLocaleString(),
            projectImage: "/assets/images/products/headphone-2.jpg",
            userImage: "/assets/images/face-1.jpg",
            comment: 0,
            revision: 5
        },
        {
            id: 2,
            email: "uilib@gmail.com",
            userName: "ui lib",
            projectName: "Gull development",
            date: new Date().toLocaleString(),
            projectImage: "/assets/images/products/headphone-2.jpg",
            userImage: "/assets/images/face-1.jpg",
            comment: 0,
            revision: 5
        },
        {
            id: 3,
            email: "uilib@gmail.com",
            userName: "ui lib",
            projectName: "Gull development",
            date: new Date().toLocaleString(),
            projectImage: "/assets/images/products/headphone-3.jpg",
            userImage: "/assets/images/face-1.jpg",
            comment: 0,
            revision: 5
        },
        {
            id: 5,
            email: "uilib@gmail.com",
            userName: "ui lib",
            projectName: "Gull development",
            date: new Date().toLocaleString(),
            projectImage: "/assets/images/products/headphone-2.jpg",
            userImage: "/assets/images/face-1.jpg",
            comment: 0,
            revision: 5
        },
        {
            id: 6,
            email: "uilib@gmail.com",
            userName: "ui lib",
            projectName: "Evalynn development",
            date: new Date().toLocaleString(),
            projectImage: "/assets/images/products/iphone-1.jpg",
            userImage: "/assets/images/face-1.jpg",
            comment: 0,
            revision: 5
        },
        {
            id: 7,
            email: "uilib@gmail.com",
            userName: "ui lib",
            projectName: "Dexum development",
            date: new Date().toLocaleString(),
            projectImage: "/assets/images/products/headphone-2.jpg",
            userImage: "/assets/images/face-1.jpg",
            comment: 0,
            revision: 5
        },
        {
            id: 8,
            email: "uilib@gmail.com",
            userName: "ui lib",
            projectName: "Gull development",
            date: new Date().toLocaleString(),
            projectImage: "/assets/images/products/headphone-3.jpg",
            userImage: "/assets/images/face-1.jpg",
            comment: 0,
            revision: 5
        },
        {
            id: 9,
            email: "uilib@gmail.com",
            userName: "ui lib",
            projectName: "Gull development",
            date: new Date().toLocaleString(),
            projectImage: "/assets/images/products/headphone-3.jpg",
            userImage: "/assets/images/face-1.jpg",
            comment: 0,
            revision: 5
        }
    ],
    roles: [
        {
            label: 'Administrador Departamento',
            titulo: "Fake",
            value: 1,
        },
        {
            label: 'Oficial Autorizado',
            titulo: "Fake",
            value: 2,
        },
        {
            label: 'Supervisor Finanzas',
            titulo: "Fake",
            value: 3,
        },
        {
            label: 'Personal Autorizado',
            titulo: "Fake",
            value: 4,
        },
        {
            label: 'Recaudador',
            titulo: "Fake",
            value: 5,
        },
        {
            label: 'Secretaria',
            titulo: "Fake",
            value: 6,
        },
        {
            label: 'Recepcionista',
            titulo: "Fake",
            value: 7,
        },
        {
            label: 'Privado',
            titulo: "Fake",
            value: 8,
        },
        {
            label: 'Auditor',
            titulo: "Fake",
            value: 9,
        }
    ],
    agencias: [
        {
            label: 'Salud Ambiental',
            value: 1
        },
        {
            label: 'Bomberos',
            value: 2
        },
        {
            label: 'DACO',
            value: 3
        },
        {
            label: 'Demo',
            value: 4
        },
        {
            label: 'ASORE (Asociación Restaurantes)',
            value: 5
        },
        {
            label: 'Junta de Calidad Ambientali',
            value: 6
        },
    ]
};

Mock.onGet("/api/list/all").reply(config => {
    const response = ListDB.list;
    return [200, response];
});
Mock.onGet("/api/roles/all").reply(config => {
    const response = ListDB.roles;
    return [200, response];
});
Mock.onGet("/api/agencias/all").reply(config => {
    const response = ListDB.agencias;
    return [200, response];
});
