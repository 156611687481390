import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const paymentDB = {
    list: [
        {
            id: shortId.generate(),
            descripcion: "asdada",
            precio: incremental++,
            subTotal: incremental++,
        },
    ],
};

Mock.onGet('/api/payment/all').reply(config => {
    return [200, paymentDB.list]
});