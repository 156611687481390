import Mock from '../mock';

const paymentsMethodsDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre",
            tipo: "Salud Ambiental",
            orden: "1",
            visible: "si",
        }
    ]
}

Mock.onGet('/api/paymentsMethods/all').reply(config => {
    return [200, paymentsMethodsDB.list]
});