import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

// Paginas generales de informacion
const AboutContainer = lazy(() => import('./pages/about'));
const AboutUsContainer = lazy(() => import('./pages/aboutUs'));
const FindDocumentContainer = lazy(() => import('./pages/validationDocument'));
const InformationSeachContainer = lazy(() => import('./pages/information_search'));
const ContactContainer = lazy(() => import('./pages/contact'));
const SearchServicePage = lazy(() => import('./pages/SearService'))
const SearchContractors = lazy(() => import('./pages/SearchContractors'))
const SearchLoading = lazy(() => import('./pages/searchLoadings'))
const SearchLicensesNtsp = lazy(() => import('./pages/SearchLicensesNtsp'))

// Paginas generales de informacion
const RequestsContainer = lazy(() => import('./pages/validateDocuments/requests'));
const OrderBooksContainer = lazy(() => import('./pages/validateDocuments/orderBooks'));
const CertificateHealthContainer = lazy(() => import('./pages/validateDocuments/certificateHealth'));

// Contacto
const ContactContainerInfo = lazy(() => import('./pages/validateDocuments/contact'));

const pagesSystemRoutes = [

    // Paginas generales de informacion
    {
        path: "/info/about",
        component: AboutContainer,
        auth: authRoles.admin
    },
    {
        path: "/info/contact",
        component: ContactContainerInfo,
        auth: authRoles.admin
    },
    {
        path: "/info/aboutUs",
        component: AboutUsContainer,
        auth: authRoles.admin
    },
    {
        path: "/info/findDocument",
        component: FindDocumentContainer,
        auth: authRoles.admin
    },
    {
        path: "/info/informationSearch",
        component: InformationSeachContainer,
        auth: authRoles.admin
    },
    {
        path: "/info/contact",
        component: ContactContainer,
        auth: authRoles.admin
    },
    {
        path: "/searchServicePublic",
        component: SearchServicePage
    },
    {
        path: "/searchContractorPublic",
        component: SearchContractors
    },
    {
        path: "/searchReportLodgings",
        component: SearchLoading
    },
    {
        path: "/searchLicensesNtsp",
        component: SearchLicensesNtsp
    },
    {
        path: "/requestsInformation/:id",
        component: RequestsContainer
    },
    {
        path: "/certificateHealthInformation/:id/:serial",
        component: CertificateHealthContainer
    },
    {
        path: "/orderBooksInformation/:serie",
        component: OrderBooksContainer
    },
]

export default pagesSystemRoutes;