import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const equipmentsDB = {
  list: [
    {
      id: shortId.generate(),
      categoriaEquipo: "Balanzas " + incremental++,
      tipoEquipo: "Balanza de Mostrador " + incremental++,
      manufactureroModelo: "Equipo 1 " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      capacidad: incremental++,
      tolerancia: "Tolerancia " + incremental++,
      estado: "Activo",
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      activa: "Si",
    },
    {
      id: shortId.generate(),
      categoriaEquipo: "Balanzas " + incremental++,
      tipoEquipo: "Balanza de Mostrador " + incremental++,
      manufactureroModelo: "Equipo 1 " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      capacidad: incremental++,
      tolerancia: "Tolerancia " + incremental++,
      estado: "Activo",
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      activa: "Si",
    },
    {
      id: shortId.generate(),
      categoriaEquipo: "Balanzas " + incremental++,
      tipoEquipo: "Balanza de Mostrador " + incremental++,
      manufactureroModelo: "Equipo 1 " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      capacidad: incremental++,
      tolerancia: "Tolerancia " + incremental++,
      estado: "Activo",
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      activa: "Si",
    },
  ],
};

Mock.onGet('/api/equipments/all').reply(config => {
  return [200, equipmentsDB.list]
});