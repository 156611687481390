import Mock from '../mock';

const documentsAssociatedRequestStatementsDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre 1",
            nombreArchivoPlantilla: "Plantilla 1",
            agencia: "Agencia 1",
            estado: "Estado 1",
            orden: "1",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/documentsAssociatedRequestStatements/all').reply(config => {
    return [200, documentsAssociatedRequestStatementsDB.list]
});