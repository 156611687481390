import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const vehiclesDB = {
    list: [
        {
            id: shortId.generate(),
            tipo: "Automóvil " + incremental++,
            subTipo: "subtipo demo" + incremental++,
            vin: incremental++,
            marca: 'marca prueba',
            modelo: 'modelo prueba',
            ano: '2011',
            tablilla: '12313 ' + incremental++,
            nombreCliente: 'Ivan Jose ' + incremental++,
            empresa: 'Empresa ' + incremental++,
            sucursal: 'Sucursal ' + incremental++,
            fechaRegistro: new Date().toLocaleDateString(),
            estado: 'Activo',
        },
        {
            id: shortId.generate(),
            tipo: "Automóvil " + incremental++,
            subTipo: "subtipo demo" + incremental++,
            vin: incremental++,
            marca: 'marca prueba',
            modelo: 'modelo prueba',
            ano: '2011',
            tablilla: '12313 ' + incremental++,
            nombreCliente: 'Ivan Jose ' + incremental++,
            empresa: 'Empresa ' + incremental++,
            sucursal: 'Sucursal ' + incremental++,
            fechaRegistro: new Date().toLocaleDateString(),
            estado: 'Activo',
        },
        {
            id: shortId.generate(),
            tipo: "Automóvil " + incremental++,
            subTipo: "subtipo demo" + incremental++,
            vin: incremental++,
            marca: 'marca prueba',
            modelo: 'modelo prueba',
            ano: '2011',
            tablilla: '12313 ' + incremental++,
            nombreCliente: 'Ivan Jose ' + incremental++,
            empresa: 'Empresa ' + incremental++,
            sucursal: 'Sucursal ' + incremental++,
            fechaRegistro: new Date().toLocaleDateString(),
            estado: 'Activo',
        },
        {
            id: shortId.generate(),
            tipo: "Automóvil " + incremental++,
            subTipo: "subtipo demo" + incremental++,
            vin: incremental++,
            marca: 'marca prueba',
            modelo: 'modelo prueba',
            ano: '2011',
            tablilla: '12313 ' + incremental++,
            nombreCliente: 'Ivan Jose ' + incremental++,
            empresa: 'Empresa ' + incremental++,
            sucursal: 'Sucursal ' + incremental++,
            fechaRegistro: new Date().toLocaleDateString(),
            estado: 'Activo',
        },
    ],
};

Mock.onGet('/api/vehicles/all').reply(config => {
    return [200, vehiclesDB.list]
});