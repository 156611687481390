import Mock from "../mock";


const holidaysDB = {
    list: [
        {
            id: "1",
            mes: "Enero",
            dia: "6",
            descripcion: "",
            visible: 'Si'
        },{
            id: "1",
            mes: "Diciembre",
            dia: "24",
            descripcion: "",
            visible: 'No'
        },
    ]
}


Mock.onGet("/api/holidaysTypes/all").reply(config => {
    const response = holidaysDB.list;
    return [200, response];
});