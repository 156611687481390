import Mock from "../mock";

const manageClientsDB = {

    list: [
        {
            id: 1,
            tipoCliente: "Persona",
            nombre: 'Jorge Gonzales Perez',
            email: "persona2@hn.com",
        },
        {
            id: 2,
            tipoCliente: "Empresa",
            nombre: 'Juan pasd dasd',
            email: "juanperez01@gmail.com ",
        },
    ]
}

Mock.onGet('/api/manageClients/all').reply(config => {
    return [200, manageClientsDB.list]
});