import Mock from '../mock';

var incremental = 0;

const summaryRequestsDB = {
    list: [
        {
            id: incremental++,
            tipoDocumento: "N/A No se emite ningún documento",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            tipoDocumento: "Certificación",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            tipoDocumento: "Certificación Eventos Especiales",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            tipoDocumento: "Certificación Máquinas de Radiación",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            tipoDocumento: "Licencia",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            tipoDocumento: "Licencia Sanitaria",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            tipoDocumento: "Licencia Ultrasonido",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            tipoDocumento: "Permiso Exhumación",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
    ]
};

Mock.onGet('/api/summaryRequests/all').reply(config => {
    return [200, summaryRequestsDB.list]
});