import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const contractorsDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitud: "4286" + incremental++,
            numeroContratista: "294" + incremental++,
            nombreContratista: "Juan Perez Garcia",
            direccion: "dir 1232",
            telefono: "43243",
            tipoNegocio: "Impermeabilización de Techos",
            ciudad: "Santiago",
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/contractors/all').reply(config => {
    return [200, contractorsDB.list]
});