
import { instanceUsers } from "app/axios/instanceUsers";

// Se inicializa la variable data
const data = [];

// Se obtienen los roles del sistema
const rolesSystem = async () => {

  // Se consume la API
  const response = await instanceUsers.get('/roles');

  // Se obtienen los datos de la respuesta
  const dataRoles = response.data.data.reduce((acc, item) => {

    // Se agrega el item
    acc.push(item.attributes.name);

    // Se retorna acc
    return acc;
  }, []);

  // Se añaden los roles al arreglo data
  data.push(dataRoles);

  // Se retorn la respuesta del servidor
  return data;
};

// Se exporta la función
export const authRoles = {
  sa: ['SA'],
  admin: ['SA', 'ADMIN'],
  editor: ['SA', 'ADMIN', 'EDITOR'],
  guest: ['SA', 'ADMIN', 'EDITOR', 'GUEST']
}