import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const licensesSarafsDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitud: 4782 + incremental++,
            servicio: "Autorización Especial de Manufacturero Virtual de Medicamentos Con Recetas para una facilidad Residente en Puerto Rico",
            npi: 930283 + incremental++,
            cliente: "Rep emp01 demo demo",
            empresa: "Empr" + incremental++,
            sucursal: "Casa Matriz",
            direccionFisica: "Fisica" + incremental++,
            direccionPostal: "Postal" + incremental++,
            ciudad: "Santiago" + incremental++,
            telefono: incremental++,
            camasAutorizadas: incremental++,
            administrador: "Horario" + incremental++,
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/licensesSarafs/all').reply(config => {
    return [200, licensesSarafsDB.list]
});