import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const licensesNtspDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitud: incremental++,
            codigoFranquicia: incremental++,
            numeroFranquicia: incremental++,
            idServicio: incremental++,
            servicio: "Hoja de Intervención | STMP " + incremental++,
            division: "Tabla XVI - Hojas Intervención " + incremental++,
            region: "REGIÓN ESTE",
            tipoComprador: "Persona / Empresa",
            supervisor: "Richard Walker F.",
            inspector: "Inspector" + incremental++,
            cliente: "Alguien demo demo",
            empresa: "Emp01 Inc.",
            sucursal: "Casa Matriz",
            npi: "930283" + incremental++,
            direccionFisica: "Calle 1723",
            telefono: "123123009900" + incremental++,
            ciudad: "Canóvanas",
            estado: "PR",
            zipCode: "39020",
            estadoSolicitud: "Aprobación Final",
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiración: new Date().toLocaleDateString(),
            paramFechaVencimientoAutorizador: new Date().toLocaleDateString(),
            paramPeticionario: "Aprobación Final",
            // paramPeticionario: "Aprobación Final",
            paramNombreComercial: "Emp01 Inc.",
            paramDireccionFisica: "Calle x",
            vehiculoTipo: "Van",
            vehiculoVin: incremental++,
            vehiculoMarca: "Mazda",
            vehiculoModelo: "M3",
            vehiculoAño: 2023,
            vehiculoTablilla: "AA11223333333333",
            vehiculoPesoBruto: "5T",
        },
        {
            id: shortId.generate(),
            numeroSolicitud: incremental++,
            codigoFranquicia: incremental++,
            numeroFranquicia: incremental++,
            idServicio: incremental++,
            servicio: "Hoja de Intervención | STMP " + incremental++,
            division: "Tabla XVI - Hojas Intervención " + incremental++,
            region: "REGIÓN ESTE",
            tipoComprador: "Persona / Empresa",
            supervisor: "Richard Walker F.",
            inspector: "Inspector" + incremental++,
            cliente: "Alguien demo demo",
            empresa: "Emp01 Inc.",
            sucursal: "Casa Matriz",
            npi: "930283" + incremental++,
            direccionFisica: "Calle 1723",
            telefono: "123123009900" + incremental++,
            ciudad: "Canóvanas",
            estado: "PR",
            zipCode: "39020",
            estadoSolicitud: "Aprobación Final",
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiración: new Date().toLocaleDateString(),
            paramFechaVencimientoAutorizador: new Date().toLocaleDateString(),
            paramPeticionario: "Aprobación Final",
            paramNombreComercial: "Emp01 Inc.",
            paramDireccionFisica: "Calle x",
            vehiculoTipo: "Van",
            vehiculoVin: incremental++,
            vehiculoMarca: "Mazda",
            vehiculoModelo: "M3",
            vehiculoAño: 2023,
            vehiculoTablilla: "AA11223333333333",
            vehiculoPesoBruto: "5T",
        },
    ],
};

Mock.onGet('/api/licensesNtsp/all').reply(config => {
    return [200, licensesNtspDB.list]
});