import Mock from '../mock';
import shortId from "shortid";

const medicineManufacturersDB = {
    list: [
        {
            id: shortId.generate(),
            nombre: "Arturo Vidal",
            telefono: "3213213123",
            direccion: "1",
            direccionPostal: "si",
            email: "fkfsmdkfmdsfk@gmail.com",
        },
        {
            id: shortId.generate(),
            nombre: "Arturo Vidal",
            telefono: "3213213123",
            direccion: "1",
            direccionPostal: "si",
            email: "fkfsmdkfmdsfk@gmail.com",
        },
    ]
};

Mock.onGet('/api/medicineManufacturers/all').reply(config => {
    return [200, medicineManufacturersDB.list]
});