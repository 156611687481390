import Mock from '../mock';
import shortId from "shortid";

const medicineDistributorsDB = {
    list: [
        {
            id: shortId.generate(),
            nombre: "Arturo Vidal",
            telefono: "3213213123",
            direccion: "Direccion fake",
            direccionPostal: "dfsdfdsfdsfds",
            pais: "USA",
            email: "kdfmkdsmfk@gmail.com"
        },
        {
            id: shortId.generate(),
            nombre: "Arturo Vidal",
            telefono: "3213213123",
            direccion: "Direccion fake",
            direccionPostal: "gfdghsghfgf",
            pais: "Puerto Rico",
            email: "kdfmkdsmfk@gmail.com"
        },
    ]
};

Mock.onGet('/api/medicineDistributors/all').reply(config => {
    return [200, medicineDistributorsDB.list]
});