import { lazy } from 'react'
import {authRoles} from "../../auth/authRoles";

const ComplaintsView = lazy(() => import('./complaints/complaintsContainer'))
const ComplaintsDetailView = lazy(() => import('./complaints/detailComplaitView/complaintsDetailView'))
const ComplaintVisitView = lazy(() =>  import('./complaintsVisits/visitsComplaintsContainer'))
const ComplaintVisitDetail = lazy(() => import('./complaintsVisits/detailComplaintVisit/visitComplaintDetail'))
const ScheduleComplaintVisit = lazy(() => import('./scheduleComplaints/scheduleComplaintContainer'))

const complaintsRoutes = [
    {
        path: "/complaints/:customerId?",
        component: ComplaintsView,
        auth: authRoles.admin
    },
    {
        path: "/complaints_detail/:id",
        component: ComplaintsDetailView,
        auth: authRoles.admin
    },
    {
        path: "/visits_complaints",
        component: ComplaintVisitView,
        auth: authRoles.admin
    },
    {
        path: "/visits_complaints_detail/:id",
        component: ComplaintVisitDetail,
        auth: authRoles.admin
    },
    {
        path: "/schedule_complaints",
        component: ScheduleComplaintVisit,
        auth: authRoles.admin
    }
]

export default complaintsRoutes