import Mock from '../mock';


const roleDB = {
    role: [
        {
            id: "1212",
            name: 'Admin',
            description: 'Administrador del sistema',
            visible: 'Si',
            permision: ['dashboard.show']
        },
        {
            id: "24234",
            name: 'Usuario',
            description: 'Usuario del Sistema',
            visible: 'No',
            permision: ['dashboard.show']
        },
    ],
    permision: [
        {
            id: 1,
            name: 'dashboard.show',
            label: 'Ver Pantalla dashboard',
            nombreUsuario: "Fake",
            rol: "Admin",
            modulo: "Modulo fake",
        },
        {
            id: 1,
            name: 'users.show',
            label: 'Ver Usuarios',
            nombreUsuario: "Fake",
            rol: "Admin",
            modulo: "Modulo fake",
        },
        {
            id: 1,
            name: 'users.delete',
            label: 'Eliminar usuarios',
            nombreUsuario: "Fake",
            rol: "Admin",
            modulo: "Modulo fake",
        }, {
            id: 1,
            name: 'users.update',
            label: 'Actualizar Usuarios',
            nombreUsuario: "Fake",
            rol: "Admin",
            modulo: "Modulo fake",
        },
    ],
};

Mock.onGet('/api/role/all').reply(config => {
    return [200, roleDB.role]
});
Mock.onGet('/api/permision/all').reply(config => {
    return [200, roleDB.permision]
});