import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";

const RoleView = lazy(() => import("./Role/RoleContainer"));
const PermisionView = lazy(() => import("./Permision/PermissionContainer"));
const PermisionUsersView = lazy(() =>
  import("./PermisionUsers/PermisionContainer")
);

const rolePermisionRoutes = [
  {
    path: "/role/add",
    component: RoleView,
    auth: authRoles.admin,
  },
  {
    path: "/role/add_permision",
    component: PermisionView,
    auth: authRoles.admin,
  },
  {
    path: "/role_users/add_permision_users/:userId",
    component: PermisionUsersView,
    auth: authRoles.admin,
  },
];

export default rolePermisionRoutes;
