import Mock from '../mock';

var incremental = 0;

const requiredDocumentDB = {
    list: [
        {
            acciones: "x",
            num: incremental++,
            id: incremental++,
            nombreDocumento: "Foto del exterior del almacén.",
            archivo: "Subido el: 05/02/2022 16:09",
            oblig: "Si",
            validacion: "Si",
        },
        {
            acciones: "x",
            num: incremental++,
            id: incremental++,
            nombreDocumento: "Foto suplementaria (1ra).",
            archivo: "Pendiente",
            oblig: "Si",
            validacion: "Si",
        },
    ]
};

Mock.onGet('/api/requiredDocument/all').reply(config => {
    return [200, requiredDocumentDB.list]
});