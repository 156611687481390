import Mock from '../mock';

var incremental = 0;

const summaryRequestsDivisionDB = {
    list: [
        {
            id: incremental++,
            division: "prueba",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            division: "- Bacteriología Sanitaria",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            division: "Ingeniería Sanitaria",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            division: "Certificación Máquinas de Radiación",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            division: "Salud Radiológica",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            division: "Higienización del Ambiente Físico Inmediato",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            division: "- Venenos Comerciales",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            division: "Zoonosis",
            pendiente: incremental++,
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
            total: incremental++,
        },
    ]
};

Mock.onGet('/api/summaryRequestsDivision/all').reply(config => {
    return [200, summaryRequestsDivisionDB.list]
});