import Mock from "../mock";

const visitDB = {
    visitList: [
        {
            id: "493",
            idSolicitud: "4750 ",
            personalAutorizado: "Inspector Externo Demo",
            oficialAutorizado: "supervisor demo 2 jkhjkh jkhkjhjk  ",
            razonVisita: "asdada ",
            cliente: "dueño today demo",
            estadoSolicitud: "En Ejecución",
            visibleCliente: "No",
            tipoVisita: "Rutina",
            estadoVisita: "Pendiente",
            resolucionVisita: "",
            fechaVisita: "01/26/2023 14:58 ",
            pueblo: "Aguadilla ",
            barrio: "Aguacate ",
            solicitudServicio: "Agencias de viajes "
        }
    ],
    visitDetailList: [
        {
            nombre: '499 Hoja de Inspección de Alimentos (03-09-2023 15:34)',
            categoria: 'Hojas de Inspección ',
            fecha: '03/09/2023 '
        }
    ],
    documentHistoryList: [
        {
            id: "4445",
            documento: "* 499 Hoja de Inspección de Alimentos (03-09-2023 15:34)",
            archivo: "499-hoja-inspeccion-alimentos-salud-(03-09-2023).pdf",
            fechaSubido: "03/09/2023 15:34 ",
            subidoPor: "Administrador Renovaciones Online MASTER (Administrador Master)",
            razonDevolucion: "",
            usuarioQueSolicitoDevolucion: ""
        }
    ]
}

Mock.onGet('/api/visitss/all').reply(config => {
    return [200, visitDB.visitList]
});
Mock.onGet('/api/visitDetailList/all').reply(config => {
    return [200, visitDB.visitDetailList]
});
Mock.onGet('/api/documentHistoryList/all').reply(config => {
    return [200, visitDB.documentHistoryList]
});
