import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Services = lazy(() => import('./services/servicesContainer'))
const EditService = lazy(() => import('./services/formsPage/editPage'))
const DetailService = lazy(() => import('./services/detailPages/detailService'))
const DocumentService = lazy(() => import('./documentServices/documentServiceContainer'))
const CategoryService = lazy(() => import('./categoryServices/categoryServiceContainer'))

const servicesRotes = [
    {
        path: "/services/",
        component: Services,
        auth: authRoles.admin
    },
    {
        path: "/edit-service/:id",
        component: EditService,
        auth: authRoles.admin
    },
    {
        path: "/detail-service/:id",
        component: DetailService,
        auth: authRoles.admin
    },
    {
        path: "/documents_services",
        component: DocumentService,
        auth: authRoles.admin
    },
    {
        path: "/service_categories",
        component: CategoryService,
        auth: authRoles.admin
    },
]


export default servicesRotes