import Mock from "../mock";

const manageCompanyDB = {

    list: [
        {
            id: 1,
            logo: "Persona",
            nombreEmpresa: 'Empresa 01',
            email: "persona2@hn.com",
            tipoEmpresa: "Comercial",
            sectorNegocio: "Agricola",
            maquinasRadiacion: "Si",
            equipoPesasMedidas: "Si",
        },
        {
            id: 1,
            logo: "Empresa",
            nombreEmpresa: 'Empresa 02',
            email: "persona2@hn.com",
            tipoEmpresa: "Asesor",
            sectorNegocio: "Campo",
            maquinasRadiacion: "No",
            equipoPesasMedidas: "Si",
        },
    ]
}

Mock.onGet('/api/manageCompany/all').reply(config => {
    return [200, manageCompanyDB.list]
});