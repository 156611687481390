import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/home/dashboardRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import agenciasRoutes from "./views/agencias/agenciasRoutes";
import usersRoutes from "./views/usuarios/usersRoutes";
import solicitudesRoutes from "./views/requests/solicitudesRoutes";
import ClientesRoutes from "./views/clientes/clientesRoutes";
import maintainersRoutes from "./views/maintainers/maintainersRoutes";
import servicesRotes from "./views/services/servicesRoutes";
import toolsRoutes from "./views/tools/toolsRoutes";
import salesRoutes from "./views/sales/salesRoutes";
import visitsRoutes from "./views/visits/visitsRoutes";
import moduleAgenciesRoutes from "./views/moduleAgencies/moduleAgenciesRoutes";
import complaintsRoutes from "./views/complaints/complaintsRoutes";
import ReportsRoutes from "./views/reports/reportsRoutes";
import LogsRoutes from "./views/logs/logsRoutes";
import SearchServiceRoutes from "./views/searchService/searchServiceRoutes";
import messageRoutes from "./views/messages/messagesRoutes";
import rolePermisionRoutes from "./views/RolesPermisions/rolePermisionRoutes";
import manageClientsRoutes from "./views/manageClients/manageClientsRoutes";
import pagesSystemRoutes from "./views/pagesSystem/pagesSystemRoutes";

// Se instalan las rutas de los modulos
const redirectRoute = [
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/dashboard/v1" />
    }
];

// Se instalan las rutas de los modulos
const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />
    }
];

// Se instalan las rutas de los modulos
const routes = [
    ...sessionsRoutes,
    {
        path: "/",
        component: AuthGuard,
        routes: [
            ...dashboardRoutes,
            ...agenciasRoutes,
            ...usersRoutes,
            ...solicitudesRoutes,
            ...ClientesRoutes,
            ...maintainersRoutes,
            ...servicesRotes,
            ...toolsRoutes,
            ...visitsRoutes,
            ...moduleAgenciesRoutes,
            ...complaintsRoutes,
            ...ReportsRoutes,
            ...LogsRoutes,
            ...SearchServiceRoutes,
            ...salesRoutes,
            ...messageRoutes,
            ...rolePermisionRoutes,
            ...manageClientsRoutes,
            ...pagesSystemRoutes,
            ...redirectRoute,
            /*...dashboardRoutes,
            ...uiKitsRoutes,
            ...formsRoutes,
            ...widgetsRoute,
            ...chartsRoute,
            ...dataTableRoute,
            ...extraKitsRoutes,
            ...pagesRoutes,
            ...iconsRoutes,
            ...invoiceRoutes,
            ...inboxRoutes,
            ...chatRoutes,
            ...taskManagerRoutes,
            ...calendarRoutes,
            ...ecommerceRoutes,
            ...contactRoutes,
            ...redirectRoute,
            ...errorRoute*/
        ]
    }
];

// Se exportan las rutas
export default routes;