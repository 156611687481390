import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const doctorsDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitud: "4286" + incremental++,
            nombreMedico: "Dr. Arturo Vidal",
            profesion: "Medico",
            especialidad: "General",
            numeroLicenciaMedico: "43243",
            direccion: "FAKE DIR",
            cliente: "DANIEL VELEZ CORREA",
            empresa: "client client",
            sucursal: "sucrlc cducd",
            fechaSolicitud: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/doctors/all').reply(config => {
    return [200, doctorsDB.list]
});