import Mock from "../mock";


const solicitudesDB = {
    list: [
        {
            id: "4746",
            agencia: "Salud Ambiental",
            oficialAutorizado: "Supervisor Depto. Salud",
            personalAutorizado: "No asignado",
            secretario: "No asignado",
            servicio: "Camiones Tanques",
            tipoCliente: "Empresa",
            cliente: "Emp01 Inc. (Empresa)",
            direccion: "Calle 1723",
            ciudad: "Canóvanas",
            estado: "PR",
            fechaSolicitud: "11/24/2022 15:02",
            estadoSolicitud: "Aprobación Final",
            fechaPago: "11/24/2022 15:02",
            fechaExpedicion: "11/24/2022",
            fechaRenovacion:"11/24/2023"
        }
    ],
    orders: [
        {
            id: '1',
            idOrden: '4779',
            serial: '5995ce171bfeca58',
            fechaOrden: '03/29/2023 15:44',
            fechaPago: '03/29/2023 15:44',
            estado: 'Pagada',
            metodoPago: 'Online - Tarjeta de Crédito',
            agencias: 'Salud Ambiental'
        }
    ],
    ordersDetail: [
        {
            solicitud: "4779",
            servicio: "Agencias de viajes (01-004-0000004779-52-0000000396) - 1/3",
            pueblo: "Canóvanas",
            sucursal: "Emp01 Inc. - Casa Matriz (Canóvanas)",
            departamento: "Salud Ambiental",
            estado: "Pendiente",
            precioAnual: "$75.00",
            cantidad: "1 año",
            processingFee: "$15.00",
            subtota: "$90.00 "
        }
    ],
    invoices: [
        {
            numeroFactura: "6448",
            cliente: "Jorge Gonzalez Perez",
            empresa: "sfs",
            sucursal: "sdfsd",
            descripcion: "asdada ",
            tipoFactura: "Factura de Deuda",
            ffactura: "03/29/2023 17:17 ",
            tieneMulta: "No",
            fConfirmacionPago: "03/29/2023 17:17",
            fvencimiento: "03/29/2023 17:17",
            fPago: "03/29/2023 17:17",
            estado: "No Pagada",
            precio: "$12.00 ",
            pf: "$12.00 ",
            descuento: "$0.00 ",
            total: "$112.00",
            agencia: "Negociado de Transporte y Otros Servicios Públicos",
            region: "REGIÓN NORTE",
            ciudad: "Camuy ",
            creadaPor: "Administrador Renovaciones Online MASTER",
        },
        {
            numeroFactura: "6448",
            cliente: "Jorge Gonzalez Perez",
            empresa: "sfs",
            sucursal: "sdfsd",
            descripcion: "asdada ",
            tipoFactura: "Factura de Deuda",
            ffactura: "03/29/2023 17:17 ",
            tieneMulta: "No",
            fConfirmacionPago: "03/29/2023 17:17",
            fvencimiento: "03/29/2023 17:17",
            fPago: "03/29/2023 17:17",
            estado: "Confirmar Pago",
            precio: "$12.00 ",
            pf: "$12.00 ",
            descuento: "$0.00 ",
            total: "$112.00",
            agencia: "Negociado de Transporte y Otros Servicios Públicos",
            region: "REGIÓN NORTE",
            ciudad: "Camuy ",
            creadaPor: "Administrador Renovaciones Online MASTER",
        }
    ],
    renovations: [
        {
            idRecordatorio: "3371",
            servicio: "Evaluación de Plan de Desalojo",
            fechaRenovacion: "03/16/2023",
            tipoCliente: "Persona",
            cliente: "Jorge Gonzalez Perez",
            empresa: "",
            sucursal: "",
            tipoLicencia: "Certificación Bomberos",
            tipoRenovación: "Manual",
            departamento: "Bomberos"
        },{
            idRecordatorio: "3387",
            servicio: "Servicio Demo CSP 1",
            fechaRenovacion: "03/29/2023",
            tipoCliente: "Persona",
            cliente: "sdada dsadada Lara",
            empresa: "",
            sucursal: "",
            tipoLicencia: "Certificación",
            tipoRenovación: "Manual",
            departamento: "Negociado de Transporte y Otros Servicios Públicos"
        },
    ],
    documents: [
        {
            idSolicitud: "4634",
            solicitudServicio: "Almacén de Empresa de Mudanzas (EM-Almacén)",
            departamento: "Negociado de Transporte y Otros Servicios Públicos",
            tipoCliente: "Persona",
            cliente: "Jorge Gonzalez Perez",
            empresa: "",
            sucursal: "",
            nombreDocumento: "Permiso de Uso expedido por la Oficina de Gerencia de Permisos, o la oficina de permisos Municipal correspondiente. El Permiso de Uso deberá especificar que corresponde al servicio público propuesto por el peticionario. ",
            validado: "No",
            fechaDocumento: "04/03/2023 ",
            visita: "",
            categoríaDeDocumento: "Documentos Requeridos",
            subidoPor: "sdfsdf",
        }
    ],
    history_documents: [
        {
            idDocumento: "4479",
            documento: "* Permiso de Uso expedido por la Oficina de Gerencia de Permisos, o la oficina de permisos Municipal correspondiente. El Permiso de Uso deberá especificar que corresponde al servicio público propuesto por el peticionario.",
            archivo: "prueba.txt",
            fechaSubido: "04/03/2023 16:53",
            subidoPor: "Administrador Renovaciones Online MASTER (Administrador Master)",
            razonDevolucion: "",
            usuarioQueSolicitoDevolucion: "",
            fechaSolicitudDevolucion: " ",
        }
    ],
    document_duplicates: [
        {
            id: '2323',
            tipoCliente: 'Empresa',
            cliente: 'Rep emp01 demo demo',
            numeroSolicitud: '41',
            nombreDocumento: 'Demo',
            fechaSolicitud: '03/17/2023',
            solicitudServicio: '',
            Departamento: 'Salud Ambiental',
            estadoSolicitud: 'Aprobado',
            estadoFactura: 'No Pagada',
        }
    ],
    sales: [
        {
            solicitud: '4780',
            servicio: 'Agencias de viajes',
            region: 'Caguas ',
            ciudad: 'Caguas',
            barrio: 'Cañabón',
            numeroFactura: '6445',
            descripción: 'Factura Agencias de viajes',
            tipo: 'Factura de Servicio',
            fFactura: '03/29/2023',
            fPago: '03/29/2023',
            estado: 'Pagada',
            precio: '$75.00',
            pf: '$15.00',
            descuento: '$0.00',
            total: '$90.00 ',
        }
    ]
}

Mock.onGet('/api/solicitudes/all').reply(config => {
    return [200, solicitudesDB.list]
});
Mock.onGet('/api/request_orders/all').reply(config => {
    return [200, solicitudesDB.orders]
});
Mock.onGet('/api/ordersDetail/all').reply(config => {
    return [200, solicitudesDB.ordersDetail]
});
Mock.onGet('/api/invoices/all').reply(config => {
    return [200, solicitudesDB.invoices]
});
Mock.onGet('/api/renovations/all').reply(config => {
    return [200, solicitudesDB.renovations]
});
Mock.onGet('/api/documents/all').reply(config => {
    return [200, solicitudesDB.documents]
});
Mock.onGet('/api/history_documents/all').reply(config => {
    return [200, solicitudesDB.history_documents]
});
Mock.onGet('/api/document_duplicates/all').reply(config => {
    return [200, solicitudesDB.document_duplicates]
});
Mock.onGet('/api/reqsales/all').reply(config => {
    return [200, solicitudesDB.sales]
});




















































