import Mock from '../mock';
import shortId from "shortid";

var date = new Date();

const medicinesServicesDB = {
    list: [
        {
            id: shortId.generate(),
            categoriaMedicamento: "Medicamentos",
            manufactureroMedicamento: "Manuf. demo 1",
            distribuidorUsa: "Dist USA Demo 1",
            distribuidorPr: "Dist PR Demo 1",
            tipoUso: "Uso en Humanos",
            nombreGenerico: "Demo generico 13 diciembre",
            nombreComercial: "comercial demo",
            presentacion: "pre",
            dosis: "do",
            empaque: "em",
            ndc: "12939-23-123l",
            fechaRegistroFda: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
            notas: "mdfksmdkfsd",
        }
    ]
};

Mock.onGet('/api/medicinesServices/all').reply(config => {
    return [200, medicinesServicesDB.list]
});