import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const registersDB = {
    registersClient: [
        {
            id: shortId.generate(),
            mes: "Enero",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Febrero",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Marzo",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Abril",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Mayo",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Junio",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Julio",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Agosto",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Septiembre",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Octubre",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Noviembre",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Diciembre",
            personas: incremental++,
            empresas: incremental++,
            gestores: incremental++,
        },
    ],
    requests: [
        {
            id: shortId.generate(),
            mes: "Enero",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Febrero",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Marzo",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Abril",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Mayo",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Junio",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Julio",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Agosto",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Septiembre",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Octubre",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Noviembre",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Diciembre",
            solicitudes: incremental++,
            solicitudesAnuladas: incremental++,
            solicitudesEfectivas: incremental++,
        },
    ],
    departmentsUsers: [
        {
            id: shortId.generate(),
            mes: "Enero",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Febrero",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Marzo",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Abril",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Mayo",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Junio",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Julio",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Agosto",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Septiembre",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Octubre",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Noviembre",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
        {
            id: shortId.generate(),
            mes: "Diciembre",
            administradoresDepartamento: incremental++,
            supervisores: incremental++,
            supervisoresFinanzas: incremental++,
            inspectores: incremental++,
            recaudadores: incremental++,
            secretarias: incremental++,
            recepcionistas: incremental++,
            privados: incremental++,
            auditores: incremental++,
        },
    ],
};

Mock.onGet('/api/clientsRegisters/all').reply(config => {
    return [200, registersDB.registersClient]
});

Mock.onGet('/api/requestsRegisters/all').reply(config => {
    return [200, registersDB.requests]
});

Mock.onGet('/api/departmentsUsersRegisters/all').reply(config => {
    return [200, registersDB.departmentsUsers]
});