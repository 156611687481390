import Mock from '../mock';

var incremental = 0;

const summaryAssignmentRequestsDB = {
    list: [
        {
            id: incremental++,
            nombreUsuario: "User" + incremental++,
            rol: "Oficial Autorizado",
            titulo: "Oficinista",
            divisiones: "Divisiones",
            pendiente: incremental++,
            evaluacion: incremental++,
            enEjecucion: incremental++,
            permisoProvisional: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            listoDeterminacion: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            denegadaDocumento: incremental++,
            suspendida: incremental++,
            vencida: incremental++,
            anulada: incremental++,
            inactivacion: incremental++,
            total: incremental++,
        },
        {
            id: incremental++,
            nombreUsuario: "User" + incremental++,
            rol: "Personal Autorizado",
            titulo: "Oficinista",
            divisones: "Divisiones",
            pendiente: incremental++,
            evaluacion: incremental++,
            enEjecucion: incremental++,
            permisoProvisional: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            listoDeterminacion: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            denegadaDocumento: incremental++,
            suspendida: incremental++,
            vencida: incremental++,
            anulada: incremental++,
            inactivacion: incremental++,
            total: incremental++,
        },
    ]
};

Mock.onGet('/api/summaryAssignmentRequests/all').reply(config => {
    return [200, summaryAssignmentRequestsDB.list]
});