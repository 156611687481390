import Mock from '../mock';

const manageInfractionsDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre",
            numero: "1",
            descripcion: "Descripcion",
            categoria: "Categoría",
            monto: "Monto",
            rebaja: "Rebaja",
            rebaja2: "Rebaja 2",
            tieneCalculoMillas: "si",
            visible: "si",
        }
    ]
};

Mock.onGet('/api/manageInfractions/all').reply(config => {
    return [200, manageInfractionsDB.list]
});