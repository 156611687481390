import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const gamesMachinesServicesDB = {
    list: [
        {
            id: shortId.generate(),
            aprobada: true,
            tipoMaquina: "Tipo de Máquina de Juego A",
            proposito: "Uso en Humano - Estudios Terapéuticos",
            fijaMovil: "Móvil",
            manufactureroModelo: "juego123213",
            empresa: "Emp01 Inc.",
            sucursal: "Casa Matriz (Canóvanas)",
            localidadUbicacion: "Bdjd",
            observaciones: "X FAKE",
        },
        {
            id: shortId.generate(),
            aprobada: true,
            tipoMaquina: "Tipo de Máquina de Juego B",
            proposito: "Uso para Investigación Científica - Académico",
            fijaMovil: "Móvil",
            manufactureroModelo: "juego" + shortId.generate(),
            empresa: "Emp01 Inc.",
            sucursal: "Casa Matriz (Canóvanas)",
            localidadUbicacion: "Bdjd",
            observaciones: "X FAKE",
        },
    ],
};

Mock.onGet('/api/gamesMachinesServices/all').reply(config => {
    return [200, gamesMachinesServicesDB.list]
});