import Mock from "../mock";


const logsDB = {
    system: [
        {
            id: '21323',
            usuario: 'Administrador Renovaciones Online MASTER',
            rol: 'Administrador Master ',
            ip: '54.92.182.109 ',
            ciudad: 'Seattle ',
            pais: 'United States ',
            browser: 'Chrome 112.0.0.0 ',
            fechaingreso: '04/13/2023 23:25',
        }
    ],
    validationLogs: [
        {
            idusuario: "1",
            grupoUsuario: "Administrador Master",
            usuario: "Administrador Renovaciones Online MASTER	",
            email: "admin@renovacionesonline.com",
            nSolicitud: "12",
            nControl: "01-045-00012-48-00009",
            serial: "",
            fecha: "04/06/2023 16:25",
        }
    ],
    echeckesLogs: [
        {
            usuario: "",
            empresa: "",
            solicitud: "",
            factura: "",
            agencia: "",
            confirmationNumber: "222763443315126 ",
            mensaje: "",
            monto: "$205 ",
            fechaIngreso: "10/08/2022 18:37",
        }
    ],
    cronJobLogs: [
        {
            id: '2234',
            url: "cron/renovation_reminder_seals/30",
            action: "renovation_reminder_seals",
            estado: "Activo",
            fecha: "08/10/2021 11:35:23",
        }
    ],
    bulkMessages: [
        {
            idOrigen: "1231",
            idDestinatario: "32343",
            asunto: "Ejemplo de Asunto",
            mensaje: "mensaje de Prueba",
            archivo: "este es el archivo",
            iGrupoEnvio: "234234",
        }
    ]
}


Mock.onGet("/api/logSystem/all").reply(config => {
    const response = logsDB.system;
    return [200, response];
});

Mock.onGet("/api/validationLogs/all").reply(config => {
    const response = logsDB.validationLogs;
    return [200, response];
});
Mock.onGet("/api/echeckesLogs/all").reply(config => {
    const response = logsDB.echeckesLogs;
    return [200, response];
});

Mock.onGet("/api/cronJobLogs/all").reply(config => {
    const response = logsDB.cronJobLogs;
    return [200, response];
});


Mock.onGet("/api/bulkMessages/all").reply(config => {
    const response = logsDB.bulkMessages;
    return [200, response];
});












