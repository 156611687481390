import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const summaryNtspDB = {
    list: [
        {
            id: shortId.generate(),
            idServicio: incremental++,
            servicios: "Almacén de Empresa de Mudanzas (EM-Almacén)",
            tipoComprador: "Persona / Empresa",
            vigentes: incremental++,
            vencidos: incremental++,
            denegados: incremental++,
            total: incremental++,
        },
        {
            id: shortId.generate(),
            idServicio: incremental++,
            servicios: "Autorización de Franquicia de 'CERT | Conductor de Empresa de Red de Transporte' (uber)",
            tipoComprador: "Persona",
            vigentes: incremental++,
            vencidos: incremental++,
            denegados: incremental++,
            total: incremental++,
        },
        {
            id: shortId.generate(),
            idServicio: incremental++,
            servicios: "Almacén de Empresa de Mudanzas (EM-Almacén)",
            tipoComprador: "Empresa",
            vigentes: incremental++,
            vencidos: incremental++,
            denegados: incremental++,
            total: incremental++,
        },
    ],
};

Mock.onGet('/api/summaryNtsp/all').reply(config => {
    return [200, summaryNtspDB.list]
});