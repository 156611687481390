import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;
var date = new Date();

const orderBooksDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitud: "3856" + incremental++,
            hojasUsadas: incremental++,
            cliente: "Dueño Empresa Dic" + incremental++,
            empresa: "Oficina Médica Dr. Guerra" + incremental++,
            sucursal: "Casa Matriz" + incremental++,
            ciudad: "Santiago",
            fechaSolicitud: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
        },
        {
            id: shortId.generate(),
            numeroSolicitud: "3856" + incremental++,
            hojasUsadas: incremental++,
            cliente: "Dueño Empresa Dic" + incremental++,
            empresa: "Oficina Médica Dr. Guerra" + incremental++,
            sucursal: "Casa Matriz" + incremental++,
            ciudad: "Santiago",
            fechaSolicitud: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
        },
        {
            id: shortId.generate(),
            numeroSolicitud: "3856" + incremental++,
            hojasUsadas: incremental++,
            cliente: "Dueño Empresa Dic" + incremental++,
            empresa: "Oficina Médica Dr. Guerra" + incremental++,
            sucursal: "Casa Matriz" + incremental++,
            ciudad: "Santiago",
            fechaSolicitud: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
        },
        {
            id: shortId.generate(),
            numeroSolicitud: "3856" + incremental++,
            hojasUsadas: incremental++,
            cliente: "Dueño Empresa Dic" + incremental++,
            empresa: "Oficina Médica Dr. Guerra" + incremental++,
            sucursal: "Casa Matriz" + incremental++,
            ciudad: "Santiago",
            fechaSolicitud: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
        },
    ],
};

Mock.onGet('/api/orderBooks/all').reply(config => {
    return [200, orderBooksDB.list]
});