import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const scheduledVisitsDB = {
    list: [
        {
            acciones: "x",
            id: shortId.generate(),
            idVisita: shortId.generate(),
            fechaVisita: new Date().toLocaleDateString(),
            estadoVisita: "Aprobación Final",
            atrasada: "No",
            idSolicitud: shortId.generate(),
            idCliente: shortId.generate(),
            idEmpresa: shortId.generate(),
            direccionCliente: "DIR FAKE",
        },
    ],
};

Mock.onGet('/api/scheduledVisits/all').reply(config => {
    return [200, scheduledVisitsDB.list]
});