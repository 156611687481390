import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const radiationMachinesDB = {
  list: [
    {
      id: shortId.generate(),
      tipoMaquina: "Mamografias " + incremental++,
      proposito: "Otro " + incremental++,
      fijaMovil: "Móvil" + incremental++,
      nombreMaquina: "Nombre maquina " + incremental++,
      manufactureroModelo: "Manufacturero y Modelo " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      estado: "Estado " + incremental++,
      idFactura: "Id Factura " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      activa: "Si",
      razonInactivacion: "NO VALIDO",
    },
    {
      id: shortId.generate(),
      tipoMaquina: "Mamografias " + incremental++,
      proposito: "Otro " + incremental++,
      fijaMovil: "Móvil" + incremental++,
      nombreMaquina: "Nombre maquina " + incremental++,
      manufactureroModelo: "Manufacturero y Modelo " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      estado: "Estado " + incremental++,
      idFactura: "Id Factura " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      activa: "Si",
      razonInactivacion: "NO VALIDO",
    },
    {
      id: shortId.generate(),
      tipoMaquina: "Mamografias " + incremental++,
      proposito: "Otro " + incremental++,
      fijaMovil: "Móvil" + incremental++,
      nombreMaquina: "Nombre maquina " + incremental++,
      manufactureroModelo: "Manufacturero y Modelo " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      estado: "Estado " + incremental++,
      idFactura: "Id Factura " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      activa: "Si",
      razonInactivacion: "NO VALIDO",
    },
    {
      id: shortId.generate(),
      tipoMaquina: "Mamografias " + incremental++,
      proposito: "Otro " + incremental++,
      fijaMovil: "Móvil" + incremental++,
      nombreMaquina: "Nombre maquina " + incremental++,
      manufactureroModelo: "Manufacturero y Modelo " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      estado: "Estado " + incremental++,
      idFactura: "Id Factura " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      activa: "Si",
      razonInactivacion: "NO VALIDO",
    },
    {
      id: shortId.generate(),
      tipoMaquina: "Mamografias " + incremental++,
      proposito: "Otro " + incremental++,
      fijaMovil: "Móvil" + incremental++,
      nombreMaquina: "Nombre maquina " + incremental++,
      manufactureroModelo: "Manufacturero y Modelo " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      estado: "Estado " + incremental++,
      idFactura: "Id Factura " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      activa: "Si",
      razonInactivacion: "NO VALIDO",
    },
    {
      id: shortId.generate(),
      tipoMaquina: "Mamografias " + incremental++,
      proposito: "Otro " + incremental++,
      fijaMovil: "Móvil" + incremental++,
      nombreMaquina: "Nombre maquina " + incremental++,
      manufactureroModelo: "Manufacturero y Modelo " + incremental++,
      numeroSerie: "Número Serie " + incremental++,
      empresa: "Empresa	" + incremental++,
      sucursal: "Sucursal " + incremental++,
      ciudad: "Ciudad " + incremental++,
      fechaRegistro: new Date().toLocaleDateString(),
      estado: "Estado " + incremental++,
      idFactura: "Id Factura " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      activa: "Si",
      razonInactivacion: "NO VALIDO",
    },
  ],
};

Mock.onGet('/api/radiationMachines/all').reply(config => {
  return [200, radiationMachinesDB.list]
});