import Mock from '../mock';

const currenciesDB = {
    list: [
        {
            id: "1",
            nombre: "Peso Chileno",
            acronimo: "CLP",
            ciudad: "Santiago",
            visible: "si",
        }
    ]
}

Mock.onGet('/api/currencies/all').reply(config => {
    return [200, currenciesDB.list]
});