import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const licensesSealsDB = {
    list: [
        {
            id: shortId.generate(),
            solicitud: "4286" + incremental++,
            nombreMedico: "Dr. Arturo Vidal",
            nombreInstitucionSalud: "Clinica Don Arturo Vidal",
            direccion: "FAKE DIR",
            telefono: 432432432 + incremental++,
            direccionPostal: "FAKEEEEEE",
            numeroSello: 321 + incremental++,
            serial: incremental++,
            paciente: "Juan Perez Rojas",
            ciudad: "Santiago",
            tipoPlanSalud: "Reforma",
            tipoCertificado: "Alimentos",
            tipoPrueba: "Tuberculina",
            fechaPrueba: new Date().toLocaleDateString(),
            tipoPrueba2: "Tuberdmckdsmcds",
            fechaPrueba2: new Date().toLocaleDateString(),
            proposito: "dkfmdskfmds",
            estado: "Emitido",
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
    ],
};

const licensesSealsSellersDB = {
    list: [
        {
            id: shortId.generate(),
            sellosComprados: incremental++,
            sellosUsados: incremental++,
        },
    ],
};


Mock.onGet('/api/licensesSeals/all').reply(config => {
    return [200, licensesSealsDB.list]
});

Mock.onGet('/api/licensesSealsSellers/all').reply(config => {
    return [200, licensesSealsSellersDB.list]
});