import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const vehiclesServicesDB = {
    list: [
        {
            id: shortId.generate(),
            acciones: "x",
            vin: "11111111111111111",
            marca: "Chevrolet",
            modelo: "Chevelle",
            año: "1969",
            tipoVehiculo: "'Van' de pasajeros",
            empresa: "Emp01 Inc.",
            sucursal: "Casa Matriz",
            numeroTablilla: "AA11223333333333",
        },
    ],
};

Mock.onGet('/api/vehiclesServices/all').reply(config => {
    return [200, vehiclesServicesDB.list]
});