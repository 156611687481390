import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";

// Se llama a los componentes de la vista de mensajes
const MessageView = lazy(() => import('./messagesView'))
const MessageDetail = lazy(() => import('./detailMessage'))

// Se definen las rutas de la vista de mensajes
const messageRoutes = [
    {
        path: "/messages",
        component: MessageView,
        auth: authRoles.admin
    },
    {
        path: "/message_detail/:id/:type",
        component: MessageDetail,
        auth: authRoles.admin
    },
];

// Se exportan las rutas de la vista de mensajes
export default messageRoutes;