import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const lodgingsDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitud: "4286" + incremental++,
            numeroLicencia: "294" + incremental++,
            tipoLicencia: "Permanente",
            nombreDueño: "Juan Perez Santiago",
            direccionFisica: "Calle Demo 1878",
            direccionPostal: "PO BOX 1847",
            telefonoContacto: "123456789",
            ciudadUbicacion: "San Juan",
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
        {
            id: shortId.generate(),
            numeroSolicitud: "4286" + incremental++,
            numeroLicencia: "294" + incremental++,
            tipoLicencia: "Permanente",
            nombreDueño: "Rep emp01 demo demo",
            direccionFisica: "Calle 1723",
            direccionPostal: "postal 3993",
            telefonoContacto: "123123009900",
            ciudadUbicacion: "Canóvanas",
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/lodgings/all').reply(config => {
    return [200, lodgingsDB.list]
});