import { lazy } from "react";
import {authRoles} from "../../auth/authRoles";

const BulkMessages = lazy(() => import('./bulkMessges/BulkMessagesContainer'))
const Logs = lazy(() => import('./systemLogs/systemLogsContainer'))
const ValidationLogs = lazy(() => import('./validationLogs/validationLogsContainer'))
const EchequesLogs = lazy(() => import('./echeckLogs/echeckLogsContainer'))
const CronJobLogs = lazy(() => import('./cronJobLogs/CronJomContainer'))
const UserActivities = lazy(() => import('./UserActivities/userActivitiesContainer'))

const LogsRoutes = [
    {
        path: "/bulkMessages",
        component: BulkMessages,
        auth: authRoles.admin
    },
    {
        path: "/logs/:customerId?",
        component: Logs,
        auth: authRoles.admin
    },
    {
        path: "/validation_logs",
        component: ValidationLogs,
        auth: authRoles.admin
    },
    {
        path: "/echeckes_logs",
        component: EchequesLogs,
        auth: authRoles.admin
    },
    {
        path: "/cronjobs_logs",
        component: CronJobLogs,
        auth: authRoles.admin
    },
    {
        path: "/activity_logs",
        component: UserActivities,
        auth: authRoles.admin
    }
]


export default LogsRoutes
