import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const summarySellersServicesDB = {
    list: [
        {
            id: shortId.generate(),
            nSello: incremental++,
            serial: shortId.generate(),
            estado: "Emitido",
            paciente: "Paciente " + incremental++,
            ciudad: "Ciudad 1",
            tipoPlanSalud: "Privado",
            tipoCertificado: "Alimentos",
            tipoPrueba: "VRDL",
            fechaPrueba: new Date().toLocaleDateString(),
            tipoPrueba2: "Tuberculina",
            fechaPrueba2: new Date().toLocaleDateString(),
            proposito: "DMSKDSDKM",
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaRenovacion: new Date().toLocaleDateString(),
            fechaCreacion: new Date().toLocaleDateString() + new Date().getTimezoneOffset().toLocaleString(),
        },
    ],
};

Mock.onGet('/api/summarySellersServices/all').reply(config => {
    return [200, summarySellersServicesDB.list]
});