import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const deathsDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitud: 4782 + incremental++,
            idRegistroElectronico: incremental++,
            nombreServicio: "Permiso de Entrenamiento",
            nombreDifunto: "Arturo Vidal",
            causaDefuncion: "Pendiente de investigación",
            mencionCovid19: "Si",
            fechaDefuncion: new Date().toLocaleDateString(),
            horaDefuncion: new Date().getHours(),
            fechaEntierro: new Date().toLocaleDateString(),
            fechaCremacion: new Date().toLocaleDateString(),
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            estadoSolicitud: "Aprobación Final",
        },
        {
            id: shortId.generate(),
            numeroSolicitud: 4782 + incremental++,
            idRegistroElectronico: incremental++,
            nombreServicio: "Permiso de Entrenamiento",
            nombreDifunto: "Arturo Vidal",
            causaDefuncion: "Pendiente de investigación",
            mencionCovid19: "Si",
            fechaDefuncion: new Date().toLocaleDateString(),
            horaDefuncion: new Date().getHours(),
            fechaEntierro: new Date().toLocaleDateString(),
            fechaCremacion: new Date().toLocaleDateString(),
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            estadoSolicitud: "Evaluación",
        },
    ],
};

Mock.onGet('/api/deaths/all').reply(config => {
    return [200, deathsDB.list]
});