import Mock from '../mock';


const neighborhoodDB = {
    list: [
        {
            id: "1",
            nombre: "Barrio 1",
            ciudad: "Ciudad 1",
            visible: "si",
        }
    ]
}

Mock.onGet('/api/neighborhood/all').reply(config => {
    return [200, neighborhoodDB.list]
});