import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

// Maquinas de Radiacion
const RadiationMachines = lazy(() =>
  import("./radiationMachines/radiationMachinesContainer")
);
const DetailsRadiationMachines = lazy(() =>
  import("./radiationMachines/modals/DetailsRadiationMachinesModal")
);

// Maquinas de Loteria
const GamesMachines = lazy(() =>
  import("./gamesMachines/gamesMachinesContainer")
);
const EditGamesMachines = lazy(() =>
  import("./gamesMachines/modals/EditGamesMachines")
);
const CreateGamesMachines = lazy(() =>
  import("./gamesMachines/modals/CreateGamesMachines")
);

// Equipos Pesas y Medidas (DACO)
const EquipmentsContainer = lazy(() =>
  import("./equipments/equipmentsContainer")
);
const EditEquipments = lazy(() => import("./equipments/modals/EditEquipments"));
const CreateEquipments = lazy(() =>
  import("./equipments/modals/CreateEquipments")
);

// Vehiculos
const VehiculosContaner = lazy(() => import("./vehicles/vehiclesContainer"));
const EditVehicles = lazy(() => import("./vehicles/modals/EditVehicles"));
const CreateVehicles = lazy(() => import("./vehicles/modals/CreateVehicles"));

// Libreta de Hojas de Pedidos
const OrderBooksContainer = lazy(() =>
  import("./orderBooks/orderBooksContainer")
);
const DetailsOrderBooks = lazy(() =>
  import("./orderBooks/modals/DetailsOrderBooks")
);
const AddPage = lazy(() => import("./orderBooks/modals/AddPage"));

// Pacientes
const PatientsContainer = lazy(() => import("./patients/patientsContainer"));
const DetailsPatients = lazy(() => import("./patients/modals/DetailsPatients"));
const SearchAddPatients = lazy(() =>
  import("./patients/modals/SearchAddPatients")
);

// Medicinas
const MedicinesContainer = lazy(() => import("./medicines/medicinesContainer"));
const DetailsMedicines = lazy(() =>
  import("./medicines/modals/DetailsMedicines")
);

// Medicinas y Manufactureros
const MedicinesManufacturersContainer = lazy(() =>
  import("./medicineManufacturers/medicineManufacturersContainer")
);
const DetailsMedicineManufacturers = lazy(() =>
  import("./medicineManufacturers/modals/DetailsMedicineManufacturers")
);

// Distribuidores de Medicamentos
const MedicineDistributorsContainer = lazy(() =>
  import("./medicineDistributors/medicineDistributorsContainer")
);

// Categorías de Medicamentos
const MedicineCategoriesContainer = lazy(() =>
  import("./medicineCategories/medicineCategoriesContainer")
);

// Aprobacion Tipos de Equipo
const EquipmentTypeApprovalContainer = lazy(() =>
  import("./equipmentTypeApproval/equipmentTypeApprovalContainer")
);
const DetailsEquipmentTypeApproval = lazy(() =>
  import("./equipmentTypeApproval/modals/DetailsEquipmentTypeApproval")
);
const EditEquipmentTypeApproval = lazy(() =>
  import("./equipmentTypeApproval/modals/EditEquipmentTypeApproval")
);
const CreateEquipmentTypeApproval = lazy(() =>
  import("./equipmentTypeApproval/modals/CreateEquipmentTypeApproval")
);

// Agentes Representantes de Medicamentos
const MedicineRepresentativeAgentsContainer = lazy(() =>
  import("./medicineRepresentativeAgents/medicineRepresentativeAgentsContainer")
);

const moduleAgenciesRoutes = [
  // Maquinas de Radiacion
  {
    path: "/moduleAgencies/radiation_machines/:id?/:customerId?",
    component: RadiationMachines,
    auth: authRoles.admin,
  },
  {
    path: "/radiation_machines/view/:id/:idRol",
    component: DetailsRadiationMachines,
    auth: authRoles.admin,
  },

  // Maquinas de Loteria
  {
    path: "/moduleAgencies/games_machines",
    component: GamesMachines,
    auth: authRoles.admin,
  },
  {
    path: "/games_machines/edit/:id/:idRol",
    component: EditGamesMachines,
    auth: authRoles.admin,
  },
  {
    path: "/games_machines/new",
    component: CreateGamesMachines,
    auth: authRoles.admin,
  },

  // Equipos Pesas y Medidas (DACO)
  {
    path: "/moduleAgencies/equipments/:customerId?",
    component: EquipmentsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/equipments/edit/:id/:idRol",
    component: EditEquipments,
    auth: authRoles.admin,
  },
  {
    path: "/equipments/new",
    component: CreateEquipments,
    auth: authRoles.admin,
  },

  // Vehiculos
  {
    path: "/moduleAgencies/vehicles",
    component: VehiculosContaner,
    auth: authRoles.admin,
  },
  {
    path: "/vehicles/edit/:id/:idRol",
    component: EditVehicles,
    auth: authRoles.admin,
  },
  {
    path: "/vehicles/new",
    component: CreateVehicles,
    auth: authRoles.admin,
  },

  // Libreta de Hojas de Pedidos
  {
    path: "/moduleAgencies/order_books",
    component: OrderBooksContainer,
    auth: authRoles.admin,
  },
  {
    path: "/orderBooks/view/:id/:hoja",
    component: DetailsOrderBooks,
    auth: authRoles.admin,
  },
  {
    path: "/orderBooks/add_page/:id",
    component: AddPage,
    auth: authRoles.admin,
  },

  // Pacientes
  {
    path: "/moduleAgencies/patients",
    component: PatientsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/patients/view/:id/:idRol",
    component: DetailsPatients,
    auth: authRoles.admin,
  },
  {
    path: "/patients/search_add_patients",
    component: SearchAddPatients,
    auth: authRoles.admin,
  },

  // Medicinas
  {
    path: "/moduleAgencies/medicines/:customerId?",
    component: MedicinesContainer,
    auth: authRoles.admin,
  },
  {
    path: "/medicines/view/:id/:idRol",
    component: DetailsMedicines,
    auth: authRoles.admin,
  },

  // Medicinas
  {
    path: "/moduleAgencies/medicine_manufacturers",
    component: MedicinesManufacturersContainer,
    auth: authRoles.admin,
  },
  {
    path: "/medicine_manufacturers/view/:id/:idRol",
    component: DetailsMedicineManufacturers,
    auth: authRoles.admin,
  },

  // Distribuidores de Medicamentos
  {
    path: "/moduleAgencies/medicine_distributors",
    component: MedicineDistributorsContainer,
    auth: authRoles.admin,
  },

  // Categorías de Medicamentos
  {
    path: "/moduleAgencies/medicine_categories",
    component: MedicineCategoriesContainer,
    auth: authRoles.admin,
  },

  // Aprobación de Tipos de Equipo
  {
    path: "/moduleAgencies/equipment_type_approval",
    component: EquipmentTypeApprovalContainer,
    auth: authRoles.admin,
  },
  {
    path: "/equipment_type_approval/view/:id",
    component: DetailsEquipmentTypeApproval,
    auth: authRoles.admin,
  },
  {
    path: "/equipment_type_approval/edit/:id",
    component: EditEquipmentTypeApproval,
    auth: authRoles.admin,
  },
  {
    path: "/equipment_type_approval/add",
    component: CreateEquipmentTypeApproval,
    auth: authRoles.admin,
  },

  // Agentes Representantes de Medicamentos
  {
    path: "/moduleAgencies/medicine_representative_agents",
    component: MedicineRepresentativeAgentsContainer,
    auth: authRoles.admin,
  },
];

export default moduleAgenciesRoutes;
