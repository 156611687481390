import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { classList } from "@utils";
import DropDownMenuItem from "./DropDownMenuItem";
import i18next from 'i18next';

const t = i18next.t.bind(i18next);
class DropDownMenu extends Component {
  state = {
    open: false,
  };

  onItemClick = (e) => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  };

  renderLevels = (items, permissions, isAdmin) =>
    items.map((item, i) => {
      if (item.sub) {
        return (
          <DropDownMenuItem key={i} item={item}>
            {this.renderLevels(item.sub)}
          </DropDownMenuItem>
        );
      } else {
        if (permissions.includes(item.path) || item.path === "/dashboard/v1") {
          return (
            <li
              key={i}
              className={classList({
                "nav-item": true,
                open: this.state.open,
              })}
              onClick={this.props.closeSecSidenav}
            >
              <NavLink activeClassName="selected" exact to={item.path}>
                <i className={`nav-icon ${item.icon}`}></i>
                <span className="item-name">{t('menu.' + item.traduction)}</span>
              </NavLink>
            </li>
          );
        }
      }
    });

  render() {
    return <ul className="childNav">{this.renderLevels(this.props.menu, this.props.permissions, this.props.isAdmin)}</ul>;
  }
}

export default DropDownMenu;
