import { lazy } from 'react'
import { authRoles } from "../../auth/authRoles";

const Clientes = lazy(() => import('./clientes.view'));
const CrearPersona = lazy(() => import('./personas.view'));
const EditPersona = lazy(() => import('./editarPersona'));
const DetailPersona = lazy(() => import('./verCliente'));
const TomarFoto = lazy(() => import('./tomarFoto'));
const BloqueoUsuarios = lazy(() => import('./bloqueos/bloqueos.view'));
const CrearEmpresa = lazy(() => import('./empresas/empresas.view'));
const VerSucursales = lazy(() => import('./sucursales/verSucursales'));
const CrearSucursales = lazy(() => import('./sucursales/agregarSucursal'));
const DetalleSucursal = lazy(() => import('./sucursales/detalleSocursal'));
const EditarSucursal = lazy(() => import('./sucursales/editarSucursales'));
const GestorView = lazy(() => import('./gestores/crearGestor'));
const CompanyAdd = lazy(() => import('./empresas/crearEmpresa'));
const ClientesEmpresa = lazy(() => import('./clientesEmpresas.view'));
const ClientesEmpresaAdd = lazy(() => import('./clientesEmpresas/clientesEmpresasAdd'));
const ClientesEmpresaEdit = lazy(() => import('./clientesEmpresas/clientesEmpresasEdit'));
const ClientesEmpresaDetails = lazy(() => import('./clientesEmpresas/clientesEmpresasDetails'));
const EditarEmpresa = lazy(() => import('./empresas/editarEmpresa'));


const ClientesRoutes = [
    {
        path: "/clients/index/:rol?/:startDate?/:endDate?",
        component: Clientes,
        auth: authRoles.admin
    },
    {
        path: "/clients/add_person",
        component: CrearPersona,
        auth: authRoles.admin
    },
    {
        path: "/clients/add_company",
        component: CrearEmpresa,
        auth: authRoles.admin
    },
    {
        path: "/clients/company/add/:id/:rol/:section",
        component: CompanyAdd,
        auth: authRoles.admin
    },
    {
        path: "/clients/edit_company/:id/:companyId/:rol/:section",
        component: EditarEmpresa,
        auth: authRoles.admin
    },
    {
        path: "/clients/add_manager",
        component: GestorView,
        auth: authRoles.admin
    },
    {
        path: "/clients/edit_person/:id/:idRol/:section?",
        component: EditPersona,
        auth: authRoles.admin
    },
    {
        path: "/clients/blocked_users/:id",
        component: BloqueoUsuarios,
        auth: authRoles.admin
    }, {
        path: "/clients/detail_person/:id/:idRol/:section?/:param?",
        component: DetailPersona,
        auth: authRoles.admin
    },
    {
        path: "/clients/company/branchs/:companyId",
        component: VerSucursales,
        auth: authRoles.admin
    },
    {
        path: "/clients/company/add_branch/:companyId/:section",
        component: CrearSucursales,
        auth: authRoles.admin
    },
    {
        path: "/clients/company/edit_branch/:companyId/:branchId",
        component: EditarSucursal,
        auth: authRoles.admin
    },
    {
        path: "/clients/company/detail_branch",
        component: DetalleSucursal,
        auth: authRoles.admin
    },
    {
        path: "/clients_company/index",
        component: ClientesEmpresa,
        auth: authRoles.admin
    },
    {
        path: "/clients_company/add",
        component: ClientesEmpresaAdd,
        auth: authRoles.admin
    },
    {
        path: "/clients_company/edit/:id/:rol",
        component: ClientesEmpresaEdit,
        auth: authRoles.admin
    },
    {
        path: "/clients_company/view/:id",
        component: ClientesEmpresaDetails,
        auth: authRoles.admin
    },
    {
        path: "/clients/tomarFoto/:id/:idRol?/:section?",
        component: TomarFoto,
        auth: authRoles.admin
    },
]

export default ClientesRoutes