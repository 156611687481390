import Mock from "./mock";

import "./db/invoice";
import "./db/calendarEvents";
import "./db/users";
import "./db/inbox";
import "./db/chat";
import "./db/todos";
import "./db/list";
import "./db/scrumBoard";
import "./db/notification";
import "./db/ecommerce";
import "./db/divisiones";
import "./db/solicitudes";
import "./db/licenseTypes";
import "./db/inspectionTypes";
import "./db/holidays";
import "./db/countries";
import "./db/city";
import "./db/neighborhood";
import "./db/currencies";
import "./db/parameters";
import "./db/servicio"
import "./db/states";
import "./db/typesResolutionsVisitingStatus";
import "./db/typesStatusTypeInvoiceDebts";
import "./db/paymentsMethods";
import "./db/statesRequest";
import "./db/typesRadiationMachines";
import "./db/machinesGeneralsRadiation";
import "./db/typesEquipmentWeightsMeasuresContainer";
import "./db/typesVehicles";
import "./db/manageInfractions";
import "./db/categoryInfractions";
import "./db/reasonsStatusRequest";
import "./db/documentsAssociatedRequestStatements";
import "./db/categoryDocuments";
import "./db/categoryCauseNatureClaims";
import "./db/bankDeposits";
import "./db/bankDepositsPF";
import "./db/bankDepositsBatches";
import "./db/visits";
import "./db/radiationMachines";
import "./db/associatedRequests";
import "./db/gamesMachines";
import "./db/equipments";
import "./db/vehicles";
import "./db/complaints";
import "./db/orderBooks";
import "./db/articles";
import "./db/patients";
import "./db/medics";
import "./db/medicines";
import "./db/medicineManufacturers";
import "./db/medicineDistributors";
import "./db/medicineCategories";
import "./db/registers";
import "./db/summaryRequest";
import "./db/summaryRequestDivision";
import "./db/summaryServices";
import "./db/summaryAssignmentRequests";
import "./db/summaryMessagesSentUser";
import "./db/summaryDocumentsValidatedUser";
import "./db/overdueRequests";
import "./db/summaryMetricTimeHistory";
import "./db/sales";
import "./db/clients";
import "./db/licensesNtsp";
import "./db/summaryNtsp";
import "./db/infractions";
import "./db/tickets";
import "./db/logs";
import "./db/searchService";
import "./db/licensesSarafs";
import "./db/clientDirectorySarafs";
import "./db/licensesSeals";
import "./db/licensesSealsDoctor";
import "./db/doctors";
import "./db/licensesSuscont";
import "./db/contractors";
import "./db/lodgings";
import "./db/deaths";
import "./db/formBuilder";
import "./db/tempBuilder";
import "./db/dataRequests";
import "./db/requiredDocument";
import "./db/messages"
import "./db/requestsVisits";
import "./db/bills";
import "./db/payment";
import "./db/historyInspection";
import "./db/detailsTicket";
import "./db/pastRequests";
import "./db/historyServices";
import "./db/role"
import "./db/radiationMachinesServices";
import "./db/gamesMachinesServices";
import "./db/equipmentsServices";
import "./db/vehiclesServices";
import "./db/medicinesServices";
import "./db/summarySellersServices";
import "./db/scheduledVisits";
import "./db/addClientService";
import "./db/clientsCompanies";
import "./db/companiesAssociated";
import "./db/agenciesAssociated";
import "./db/manageClients";
import "./db/manageCompany";
import "./db/equipmentTypeApproval";

Mock.onAny().passThrough();
