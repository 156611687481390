import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const historyServicesDB = {
    list: [
        {
            id: shortId.generate(),
            servicio: "Hoja de Intervención | Almacén",
            tipoComprador: "Persona/Empresa",
            cambiadoPor: "Sistema Renovaciones Online",
            rol: "Sistema",
            fecha: new Date().toLocaleDateString(),
        },
    ],
    historyRequestsStage: [
        {
            id: shortId.generate(),
            estado: "Pendiente",
            notasMotivo: "",
            cambiadoPor: "Sistema Renovaciones Online",
            rol: "Sistema",
            fecha: new Date().toLocaleDateString(),
        },
        {
            id: shortId.generate(),
            estado: "En Ejecución",
            notasMotivo: "",
            cambiadoPor: "Richard Walker F.",
            rol: "Oficial Autorizado",
            fecha: new Date().toLocaleDateString(),
        },
    ],
    historyAssignedUsers: [
        {
            idUsuario: shortId.generate(),
            usuario: "Richard Walker F.",
            rol: "Oficial Autorizado",
            asignadoPor: "Richard Walker F.",
            fecha: new Date().toLocaleDateString(),
        },
    ],
    historyDateChanges: [
        {
            idUsuario: shortId.generate(),
            tipoCambio: "Fecha Expedición",
            fecha: new Date().toLocaleDateString(),
            cambiadoPor: "Sistema Renovaciones Online",
            rol: "Oficial Autorizado",
            fechaCambio: new Date().toLocaleDateString(),
        },
        {
            idUsuario: shortId.generate(),
            tipoCambio: "Fecha Expedición",
            fecha: new Date().toLocaleDateString(),
            cambiadoPor: "Sistema Renovaciones Online",
            rol: "Oficial Autorizado",
            fechaCambio: new Date().toLocaleDateString(),
        },
        {
            idUsuario: shortId.generate(),
            tipoCambio: "Fecha Expedición",
            fecha: new Date().toLocaleDateString(),
            cambiadoPor: "Sistema Renovaciones Online",
            rol: "Oficial Autorizado",
            fechaCambio: new Date().toLocaleDateString(),
        },
        {
            idUsuario: shortId.generate(),
            tipoCambio: "Fecha Expedición",
            fecha: new Date().toLocaleDateString(),
            cambiadoPor: "Sistema Renovaciones Online",
            rol: "Sistema",
            fechaCambio: new Date().toLocaleDateString(),
        },
    ],
    historyDocumentReturns: [
        {
            idDocumento: shortId.generate(),
            documentoRequerido: "Documento Fake",
            razonDevolucion: "Razon Fake",
            usuarioDevolucion: "Richard Walker F.",
            fechaDevolucion: new Date().toLocaleDateString(),
        },
    ],
    historyRequestsReturns: [
        {
            id: shortId.generate(),
            idSolicitud: shortId.generate(),
            oficialAutorizado: "Richard Walker F.",
            personalAutorizado: "Richard Walker F.",
            razon: "fkdfmkdsfmdskfmkdsfmds",
            usuarioDevolucion: "Richard Walker F.",
            fechaDevolucion: new Date().toLocaleDateString(),
        },
    ],
    historyGenerationLicenses: [
        {
            id: shortId.generate(),
            agencia: "NTSP",
            servicio: "Hoja de Intervención | Almacén",
            nombreCliente: "Rep emp01 demo demo",
            empresa: "Emp01 Inc.",
            sucursal: "Emp01 Inc.",
            idSolicitud: 4630,
            estadoSolicitud: "Inspección Realizada",
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaPago: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
            tipoLicencia: "Hoja de Intervención" + shortId.generate(),
            generadaPor: "Richard Walker F.",
            fechaGeneracion: new Date().toLocaleDateString(),
        },
        {
            id: shortId.generate(),
            agencia: "NTSP",
            servicio: "Hoja de Intervención | Almacén",
            nombreCliente: "Rep emp01 demo demo",
            empresa: "Emp01 Inc.",
            sucursal: "Emp01 Inc.",
            idSolicitud: 4630,
            estadoSolicitud: "Inspección Realizada",
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaPago: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
            tipoLicencia: "Hoja de Intervención" + shortId.generate(),
            generadaPor: "Richard Walker F.",
            fechaGeneracion: new Date().toLocaleDateString(),
        },
        {
            id: shortId.generate(),
            agencia: "NTSP",
            servicio: "Hoja de Intervención | Almacén",
            nombreCliente: "Rep emp01 demo demo",
            empresa: "Emp01 Inc.",
            sucursal: "Emp01 Inc.",
            idSolicitud: 4630,
            estadoSolicitud: "Inspección Realizada",
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaPago: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
            tipoLicencia: "Hoja de Intervención" + shortId.generate(),
            generadaPor: "Richard Walker F.",
            fechaGeneracion: new Date().toLocaleDateString(),
        },
        {
            id: shortId.generate(),
            agencia: "NTSP",
            servicio: "Hoja de Intervención | Almacén",
            nombreCliente: "Rep emp01 demo demo",
            empresa: "Emp01 Inc.",
            sucursal: "Emp01 Inc.",
            idSolicitud: 4630,
            estadoSolicitud: "Inspección Realizada",
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaPago: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
            tipoLicencia: "Hoja de Intervención" + shortId.generate(),
            generadaPor: "Richard Walker F.",
            fechaGeneracion: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/historyServices/all').reply(config => {
    return [200, historyServicesDB.list]
});

Mock.onGet('/api/historyRequestsStage/all').reply(config => {
    return [200, historyServicesDB.historyRequestsStage]
});

Mock.onGet('/api/historyAssignedUsers/all').reply(config => {
    return [200, historyServicesDB.historyAssignedUsers]
});

Mock.onGet('/api/historyDateChanges/all').reply(config => {
    return [200, historyServicesDB.historyDateChanges]
});

Mock.onGet('/api/historyDocumentReturns/all').reply(config => {
    return [200, historyServicesDB.historyDocumentReturns]
});

Mock.onGet('/api/historyRequestsReturns/all').reply(config => {
    return [200, historyServicesDB.historyRequestsReturns]
});

Mock.onGet('/api/historyGenerationLicenses/all').reply(config => {
    return [200, historyServicesDB.historyGenerationLicenses]
});