import Mock from '../mock';

var incremental = 0;

const summaryMessagesSentUserDB = {
    list: [
        {
            id: incremental++,
            nombreUsuario: "User" + incremental++,
            rol: "Oficial Autorizado",
            titulo: "Oficinista",
            divisiones: "Divisiones",
            totalMensajesEnviados: incremental++,
        },
        {
            id: incremental++,
            nombreUsuario: "User" + incremental++,
            rol: "Personal Autorizado",
            titulo: "Oficinista",
            divisones: "Divisiones",
            totalMensajesEnviados: incremental++,
        },
    ]
};

Mock.onGet('/api/summaryMessagesSentUser/all').reply(config => {
    return [200, summaryMessagesSentUserDB.list]
});