import Mock from "../mock";


const complaintsDB = {
    complaints: [
        {
            id: "123",
            personalAutorizado: "demopersonal demo demo",
            numeroQuerella: "#103",
            fechaQuerella: "03/13/2023",
            fechaInvestigación: "03/09/2023",
            querellante: "Rep emp01 demo demo",
            justificado: "Si",
            estadoQuerella: "En Ejecución",
            agencia: "Salud Ambiental",
            ciudad: "Aguas Buenas",
        }
    ],
    documentTab: [
        {
            id:'23423',
            nombre: 'Prueba',
            fecha: '03/31/2023',
            subidoPor: 'Administrador Renovaciones Online MASTER (Administrador Master)'
        }
    ],
    visits: [
        {
            id: "511",
            razon: "Asasa",
            mostrarCliente: "Si",
            fechaVisita: "04/11/2023 12:15",
            tipoVisita: "Rutina",
            estado:"Pendiente",
            resolucionVisita:"",
        }
    ],
    complaintVisit: [
        {
            ID: "511",
            personalAutorizado: "Rep",
            razónDeVisita: "emp01",
            cliente: "demo",
            visibleParaCliente: "demo",
            tipoDeVisita: "Si",
            estadoDeVisita: "Pendiente",
            resoluciónDeVisita: "Rutina",
            fechaDeVisita: "04/11/2023 12:15",
            departamento: "Salud Ambiental ",
            ciudad: "Aguas Buenas",
            querella: "104"
        }
    ]
}

Mock.onGet('/api/complaints/all').reply(config => {
    return [200, complaintsDB.complaints]
});
Mock.onGet('/api/documentTab/all').reply(config => {
    return [200, complaintsDB.documentTab]
});
Mock.onGet('/api/visits/all').reply(config => {
    return [200, complaintsDB.visits]
});
Mock.onGet('/api/complaintVisit/all').reply(config => {
    return [200, complaintsDB.complaintVisit]
});