import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

// Clientes
const clientsContainers = lazy(() => import('./clients/clientsContainer'));

// Empresas
const companyContainer = lazy(() => import('./company/companyContainer'));

const manageClientsRoutes = [
    // Agregar Clientes
    {
        path: "/manageClients/clients",
        component: clientsContainers,
        auth: authRoles.admin
    },

    // Empresas
    {
        path: "/manageClients/company",
        component: companyContainer,
        auth: authRoles.admin
    },
]

export default manageClientsRoutes;