import Mock from '../mock';
import shortId from "shortid";

var incremental = 0;

const medicineCategoriesDB = {
    list: [
        {
            id: shortId.generate(),
            slug: "medicines",
            nombre: "3213213123",
            nombreEng: "Direccion fake",
            orden: incremental++,
            visible: "Si",
        },
        {
            id: shortId.generate(),
            slug: "natural-products",
            nombre: "3213213123",
            nombreEng: "Direccion fake",
            orden: incremental++,
            visible: "Si",
        },
        {
            id: shortId.generate(),
            slug: "vitamins",
            nombre: "3213213123",
            nombreEng: "Direccion fake",
            orden: incremental++,
            visible: "Si",
        },
        {
            id: shortId.generate(),
            slug: "nutritional-supplements",
            nombre: "3213213123",
            nombreEng: "Direccion fake",
            orden: incremental++,
            visible: "Si",
        },
        {
            id: shortId.generate(),
            slug: "artifacts",
            nombre: "3213213123",
            nombreEng: "Direccion fake",
            orden: incremental++,
            visible: "Si",
        },
    ]
};

Mock.onGet('/api/medicineCategories/all').reply(config => {
    return [200, medicineCategoriesDB.list]
});