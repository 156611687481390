import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const licensesSealsDoctorDB = {
    list: [
        {
            id: shortId.generate(),
            solicitud: "4286" + incremental++,
            nombreMedico: "Dr. Arturo Vidal",
            nombreInstitucionSalud: "Clinica Don Arturo Vidal",
            direccion: "FAKE DIR",
            telefono: 432432432 + incremental++,
            direccionPostal: "FAKEEEEEE",
            fechaCreacionSolicitud: new Date().toLocaleDateString(),
            totalCertificadosEmitidos: incremental++,
            totalCertificadosAnulados: incremental++,
        },
    ],
};

Mock.onGet('/api/licensesSealsDoctor/all').reply(config => {
    return [200, licensesSealsDoctorDB.list]
});