import Mock from '../mock';

var incremental = 0;

const summaryMetricTimeHistoryDB = {
    list: [
        {
            idServicio: incremental++,
            servicio: "Autorización Especial de Manufacturero Virtual de Medicamentos Con Recetas para una facilidad Residente en Puerto Rico",
            tipoDocumento: "N/A No se emite ningún documento",
            tipoComprador: "Comprador 1",
            enEjecucion: incremental++,
            inspeccionRealizada: incremental++,
            inspeccionDenegada: incremental++,
            aprobacionFinal: incremental++,
            denegada: incremental++,
            anulada: incremental++,
            listoDeterminacion: incremental++,
            evaluacion: incremental++,
            inactivacion: incremental++,
            archivado: incremental++,
        },
    ]
};

Mock.onGet('/api/summaryMetricTimeHistory/all').reply(config => {
    return [200, summaryMetricTimeHistoryDB.list]
});