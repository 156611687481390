import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const dataRequestsDB = {
    list: [
        {
            id: shortId.generate(),
            acciones: "null",
            servicio: "Hoja de Intervención | Almacén 1/1",
            empresaSucursal: "Emp01 Inc. - suc emp01 (Caguas)",
            agencias: "Negociado de Transporte y Otros Servicios Públicos ",
        },
    ],
};

const dataDocumentRequestsDB = {
    list: [
        {
            id: shortId.generate(),
            acciones: "null",
            nombre: "Foto del exterior del almacén.",
            categoria: "Documentos Requeridos",
            fecha: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
        },
        {
            id: shortId.generate(),
            acciones: "null",
            nombre: "Foto del exterior del almacén.",
            categoria: "Documentos Requeridos",
            fecha: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
        },
        {
            id: shortId.generate(),
            acciones: "null",
            nombre: "32132121",
            categoria: "Generales",
            fecha: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
        },
    ],
};

const dataHistoryRequestsDB = {
    list: [
        {
            id: incremental++,
            acciones: "null",
            idDocumento: 4261 + incremental++,
            documento: "* Foto del exterior del almacén.",
            archivo: "demo.txt",
            fechaSubido: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
            subidoPor: "Richard Walker F. (Oficial Autorizado)",
            razonDevolucion: "kfkdsfmkmfs",
            usuarioSolicitoDevolucion: "DEMO",
            fechaSolicitudDevolucion: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
        },
        {
            id: incremental++,
            acciones: "null",
            idDocumento: 4261 + incremental++,
            documento: "* Foto del exterior del almacén.",
            archivo: "demo.txt",
            fechaSubido: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
            subidoPor: "Richard Walker F. (Oficial Autorizado)",
            razonDevolucion: "kfkdsfmkmfs",
            usuarioSolicitoDevolucion: "DEMO",
            fechaSolicitudDevolucion: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
        },
        {
            id: incremental++,
            acciones: "null",
            idDocumento: 4261 + incremental++,
            documento: "* Foto del exterior del almacén.",
            archivo: "demo.txt",
            fechaSubido: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
            subidoPor: "Richard Walker F. (Oficial Autorizado)",
            razonDevolucion: "kfkdsfmkmfs",
            usuarioSolicitoDevolucion: "DEMO",
            fechaSolicitudDevolucion: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
        },
    ],
};

const dataHistoryRequestsSheetDB = {
    list: [
        {
            id: incremental++,
            modificadoPor: "Administrador Renovaciones Online MASTER",
            rol: "Administrador Master",
            fechaModificacion: new Date().toLocaleDateString() + new Date().getTime().toLocaleString(),
            acciones: "#",
        },
    ],
};

Mock.onGet('/api/dataRequests/all').reply(config => {
    return [200, dataRequestsDB.list]
});

Mock.onGet('/api/dataDocumentRequests/all').reply(config => {
    return [200, dataDocumentRequestsDB.list]
});

Mock.onGet('/api/dataHistoryRequests/all').reply(config => {
    return [200, dataHistoryRequestsDB.list]
});

Mock.onGet('/api/dataHistoryRequestsSheet/all').reply(config => {
    return [200, dataHistoryRequestsSheetDB.list]
});