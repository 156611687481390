import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const aumentarValor = (val, string) => {

  var valor = val++;
  var concadenado = string + " " + valor;

  return concadenado;
}

const bankDepositsDB = {
  list: [
    {
      id: shortId.generate(),
      fechaTransaccion: new Date().toLocaleDateString(),
      fechaDeposito: new Date().toLocaleDateString(),
      nombrePersona: "Nombre" + incremental++,
      nombreEmpresa: "Empresa " + incremental++,
      servicio: "Servicio " + incremental++,
      tipoCuenta: "Check" + incremental++,
      tipoTarjetaCredito: "VISA" + incremental++,
      confirmationNumber: "Confirmation Number " + incremental++,
      nroAutorizacion: "Nro. Autorización " + incremental++,
      creditCard: "Credit Card " + incremental++,
      bach: "Bach " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      idEmpresa: "Id Empresa " + incremental++,
      idSolicitud: "Id Solicitud " + incremental++,
      idFactura: "Id Factura " + incremental++,
      tipoFactura: "Tipo Factura " + incremental++,
      agencia: "Agencia " + incremental++,
      monto: "Monto " + incremental++,
      descriptorACH: "DescriptorACH " + incremental++,
      descriptorC: "DescriptorCC " + incremental++,
      midNumber: "MID Number " + incremental++,
      cuenta: "Cuenta " + incremental++,
    },
    {
      id: shortId.generate(),
      fechaTransaccion: new Date().toLocaleDateString(),
      fechaDeposito: new Date().toLocaleDateString(),
      nombrePersona: "Nombre" + incremental++,
      nombreEmpresa: "Empresa " + incremental++,
      servicio: "Servicio " + incremental++,
      tipoCuenta: "Check" + incremental++,
      tipoTarjetaCredito: "VISA" + incremental++,
      confirmationNumber: "Confirmation Number " + incremental++,
      nroAutorizacion: "Nro. Autorización " + incremental++,
      creditCard: "Credit Card " + incremental++,
      bach: "Bach " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      idEmpresa: "Id Empresa " + incremental++,
      idSolicitud: "Id Solicitud " + incremental++,
      idFactura: "Id Factura " + incremental++,
      tipoFactura: "Tipo Factura " + incremental++,
      agencia: "Agencia " + incremental++,
      monto: "Monto " + incremental++,
      descriptorACH: "DescriptorACH " + incremental++,
      descriptorC: "DescriptorCC " + incremental++,
      midNumber: "MID Number " + incremental++,
      cuenta: "Cuenta " + incremental++,
    },
    {
      id: shortId.generate(),
      fechaTransaccion: new Date().toLocaleDateString(),
      fechaDeposito: new Date().toLocaleDateString(),
      nombrePersona: "Nombre" + incremental++,
      nombreEmpresa: "Empresa " + incremental++,
      servicio: "Servicio " + incremental++,
      tipoCuenta: "Check" + incremental++,
      tipoTarjetaCredito: "VISA" + incremental++,
      confirmationNumber: "Confirmation Number " + incremental++,
      nroAutorizacion: "Nro. Autorización " + incremental++,
      creditCard: "Credit Card " + incremental++,
      bach: "Bach " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      idEmpresa: "Id Empresa " + incremental++,
      idSolicitud: "Id Solicitud " + incremental++,
      idFactura: "Id Factura " + incremental++,
      tipoFactura: "Tipo Factura " + incremental++,
      agencia: "Agencia " + incremental++,
      monto: "Monto " + incremental++,
      descriptorACH: "DescriptorACH " + incremental++,
      descriptorC: "DescriptorCC " + incremental++,
      midNumber: "MID Number " + incremental++,
      cuenta: "Cuenta " + incremental++,
    },
    {
      id: shortId.generate(),
      fechaTransaccion: new Date().toLocaleDateString(),
      fechaDeposito: new Date().toLocaleDateString(),
      nombrePersona: "Nombre" + incremental++,
      nombreEmpresa: "Empresa " + incremental++,
      servicio: "Servicio " + incremental++,
      tipoCuenta: "Check" + incremental++,
      tipoTarjetaCredito: "VISA" + incremental++,
      confirmationNumber: "Confirmation Number " + incremental++,
      nroAutorizacion: "Nro. Autorización " + incremental++,
      creditCard: "Credit Card " + incremental++,
      bach: "Bach " + incremental++,
      idCliente: "Id Cliente " + incremental++,
      idEmpresa: "Id Empresa " + incremental++,
      idSolicitud: "Id Solicitud " + incremental++,
      idFactura: "Id Factura " + incremental++,
      tipoFactura: "Tipo Factura " + incremental++,
      agencia: "Agencia " + incremental++,
      monto: "Monto " + incremental++,
      descriptorACH: "DescriptorACH " + incremental++,
      descriptorC: "DescriptorCC " + incremental++,
      midNumber: "MID Number " + incremental++,
      cuenta: "Cuenta " + incremental++,
    }
  ],
};

Mock.onGet('/api/bankDeposits/all').reply(config => {
  return [200, bankDepositsDB.list]
});