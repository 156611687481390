import Mock from "../mock";

const servicioDB = {
    list: [
        {
            id: "2417",
            code: "3543",
            slug: "",
            nombre: "Certificado de Registro Trienal de Medicamentos para Ensayos Clínicos en Institución de Educación Superior (Solicitud Inicial)",
            tipoComprador: "Cualquiera",
            agencia: "SARAFS",
            precio: "Post Pago",
            duracion: "1",
            tipoDuracion: "Años",
            visible: "si",
            tipoLicencia: "Certificado de Registro Trienal",
            estado: "PR",
            divisiones: "Divisiones SARAFS",
            primerParrafo: "",
            primerParrafoRenovacion: "",
            validarVigencia: "Si",
            multiplesComprasSucursal: "no",
        }
    ],
    serviceDocument: [
        {
            id: "123",
            nombre: "sdada",
            descripcion: "Test descripción",
            categoriaDocumento: "categoría doc",
            agencia: "SARAFS",
            fecha: "12/06/2017 03:49",
            subidoPor: "Jose Pablo",
            visible: "Si",
        },
    ],
    serviceDocumentRequired: [
        {
            id: "7062",
            nombre: "Doc 1",
            slug: "1",
            orden: "1",
            obligatorio: "No",
            entregable: "No",
            validacion: "No",
            visible: "Si",
            extensionesPermitidas: ".jpg, .pdf, png",
            formularioAsociado: "text",
            fechaCreacion: "12/06/2017 03:49",
            fechaModificacion: "12/06/2017 03:49",
        }
    ],
    serviceParam: [
        {
            id: "123",
            nombre: "Documento para ser Otorgado a",
            slug: "documento-para",
            orden: "10",
            tipo: "Texto",
            obligatorio: "Si",
            campoDd: "User.name",
            ocultoCarro: "",
            usoOficial: "",
            minimo: "",
            maximo: "",
            subParametros: "1",
            tipoSolicitud: "cualquiera",
            fechaCreacion: "12/06/2017 03:49",
            fechaModificacion: "03/14/2023 23:17"
        }
    ],
    serviceSuggested: [
        {
            id: "123",
            nombre: "Documento para ser Otorgado a",
            tipoComprador: "documento-para",
            agencia: "10",
        }
    ],
    servicerequisito: [
        {
            id: "123",
            nombre: "Adiestramiento Aplicación y Lectura Prueba de Tuberculina ",
            tipoComprador: "documento-para",
            agencia: "10",
        }
    ],
    serviceParamSub: [
        {
            id: "234324",
            nombre: "Nombre test",
            slug: "",
            orden: "12",
            codigo: "234234",
            tipo: "tipo",
            precio: "23423",
            tipoDuracion: "Minutos",
            duracion: 60,
            visible: "Si",
        }
    ],
    documentService: [
        {
            id: "1",
            nombre: "sdada",
            categoriaDocumento: "Formularios",
            servicioAsociado: "Certificado de Registro Trienal de Medicamentos para Ensayos Clínicos en Institución de Educación Superior (Solicitud Inicial)",
            tipoComprador: "SARAFS",
            agencia: "SARAFS",
            fechaCreacion: "03/23/2023",
            visible: "Si",
        }
    ],
    categoryService: [
        {
            id: "123",
            nombre: "Servicios B",
            slug: "sdfsd",
            agencia: "Salud Ambiental",
            visible: "Si"
        }
    ],
}

Mock.onGet('/api/services/all').reply(config => {
    return [200, servicioDB.list]
});
Mock.onGet('/api/services/document').reply(config => {
    return [200, servicioDB.serviceDocument]
});
Mock.onGet('/api/services/documentRequired').reply(config => {
    return [200, servicioDB.serviceDocumentRequired]
});
Mock.onGet('/api/services/serviceParam').reply(config => {
    return [200, servicioDB.serviceParam]
});
Mock.onGet('/api/services/serviceSuggested').reply(config => {
    return [200, servicioDB.serviceSuggested]
});
Mock.onGet('/api/services/requisito').reply(config => {
    return [200, servicioDB.servicerequisito]
});
Mock.onGet('/api/services/serviceParamSub').reply(config => {
    return [200, servicioDB.serviceParamSub]
});
Mock.onGet('/api/services/documentService').reply(config => {
    return [200, servicioDB.documentService]
});
Mock.onGet('/api/services/categoryService').reply(config => {
    return [200, servicioDB.categoryService]
});
