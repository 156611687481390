import history from "@history.js";
import jwtAuthService from "../../services/jwtAuthService";

// Actions
export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

// Metodo encargado de setear el usuario
export function setUserData(user) {

  // Se retorna una funcion
  return dispatch => {

    // Se setea el usuario
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

// Metodo encargado de remover el usuario
export function logoutUser() {

  // Se retorna una funcion
  return dispatch => {

    // Se llama al servicio de logout
    jwtAuthService.logout();

    // Se redirecciona al login
    history.push({
      pathname: "/session/signin"
    });

    // Se dispara la accion de logout
    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}
