import Mock from "../mock";


const licenseDB = {
    list: [
        {
            id: "1",
            nombre: "Certificación ",
            nombreCorto: "CRT",
            nombreArchivo: "certificacion",
            tipoCertificado: 'Automático',
            agencia: 'Salud Ambiental',
            orden: "1",
            visible: 'Si',
        }
    ]
}


Mock.onGet("/api/licenseTypes/all").reply(config => {
    const response = licenseDB.list;
    return [200, response];
});