import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const clientsDB = {
    list: [
        {
            id: shortId.generate(),
            agencia: "Negociado de Transporte y Otros Servicios Públicos",
            servicio: "Hoja de Intervención | STMP",
            tipoCliente: "Empresa",
            idCliente: incremental++,
            numeroSeguroSocialPersonal: incremental++,
            numeroSeguroSocialPatronal: incremental++,
            nombreCliente: "Demo Demo",
            emailCliente: "test@gmail.com",
            idEmpresa: incremental++,
            empresa: "Empr" + incremental++,
            sucursal: "Casa Matriz",
            direccion: "Direccion" + incremental++,
            zipCode: incremental++,
            ciudad: "Santiago" + incremental++,
            telefono: incremental++,
            celular: incremental++,
            npi: incremental++,
            horario: "Horario" + incremental++,
            tipoSolicitud: "Nueva",
            idSolicitud: incremental++,
            estadoSolicitud: "Aprobacion Final",
            fechaSolicitud: new Date().toLocaleDateString(),
            fechaPago: new Date().toLocaleDateString(),
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/clients/all').reply(config => {
    return [200, clientsDB.list]
});