import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const infractionsDB = {
    list: [
        {
            id: shortId.generate(),
            idInfraccion: shortId.generate(),
            categoria: "Autorizaciones (A-1)",
            numero: "A-1.01" + incremental++,
            nombreInfraccion: "Servicio No Autorizado",
            montoInfraccion: "$2,000" + incremental++,
            totalBoletos: incremental++,
            totalMontoInfraccion: "$12,000" + incremental++,
            totalInvoicesPagadas: "$12,000" + incremental++,
            totalInvoicesNoPagadas: "$12,000" + incremental++,
            totalInvoicesConfirmarPagoECheck: "$12,000" + incremental++,
            totalInvoicesSinPf: "$12,000" + incremental++,
        },
    ],
};

Mock.onGet('/api/infractions/all').reply(config => {
    return [200, infractionsDB.list]
});