import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const clientDirectorySarafsDB = {
    list: [
        {
            id: shortId.generate(),
            numeroSolicitudLicencia: 4782 + incremental++,
            servicio: "Autorización Especial de Manufacturero Virtual de Medicamentos Con Recetas para una facilidad Residente en Puerto Rico",
            nombreFacilidad: "dksmfkdsmfkds",
            dueñoFacilidad: "fdsmkfdsmfkdsmfkds",
            direccionFacilidad: "FAKE DIR",
            telefonoFacilidad: "0334o032432",
            emailFacilidad: "dsfkdsm@gmail.com",
            cliente: "Rep emp01 demo demo",
            empresa: "Empr" + incremental++,
            sucursal: "Casa Matriz",
            ciudad: "Santiago" + incremental++,
            numeroLicenciaInterno: 324329493,
            estado: "Pendiente",
            fechaExpedicion: new Date().toLocaleDateString(),
            fechaExpiracion: new Date().toLocaleDateString(),
        },
    ],
};

Mock.onGet('/api/clientDirectorySarafs/all').reply(config => {
    return [200, clientDirectorySarafsDB.list]
});