import Mock from '../mock';

const statesDB = {
    list: [
        {
            id: "1",
            nombre: "Nombre 1",
            valor: "Valor 1",
            descripcion: "Descripcion",
            visible: "si",
            pais: "Chile",
        }
    ]
};

Mock.onGet('/api/states/all').reply(config => {
    return [200, statesDB.list]
});