import Mock from "../mock";
import shortId from "shortid";

var incremental = 0;

const associatedRequestDB = {
    list: [
        {
            id: shortId.generate(),
            servicio: "Máquinas CT Scan " + incremental++,
            fechaSolicitud: new Date().toLocaleDateString(),
            estado: "Aprobación Final" + incremental++,
            fechaPago: new Date().toLocaleDateString(),
            departamento: "	Salud Ambiental  " + incremental++,
            fechaRenovacion: new Date().toLocaleDateString(),
            agencia: "Agencia " + incremental++,
        },
    ],
};

Mock.onGet('/api/associatedRequest/all').reply(config => {
    return [200, associatedRequestDB.list]
});